import * as React from 'react'
import Mod404 from './Mod404'
import ModEditBlock from './common/ModEditBlock'
import ModTemplateBlockList from './templates/ModTemplateBlockList'
import RootState, { LoadingState } from '../vo/RootState'
import { offlineImage } from '../common/OfflineMedia'
import { Page, Expertise, News, Lawyer, Publication, PageType, FilterType } from '../interfaces/Interfaces'
import ModBlockHeader from './blocks/ModBlockHeader'
import ModBlockFilter from './blocks/ModBlockFilter'
import ModTemplateOffice from './templates/ModTemplateOffice'
import ModBlockSearch from './blocks/ModBlockSearch'
import ModAnchorTag from './common/ModAnchorTag'
import ModAnimation from './common/ModAnimation'
import { FormattedMessage } from 'react-intl'

export interface ModTemplateBlockListProps {
  rootState: RootState
  currentLanguage: string
  displaySize: string
}

export interface ModTemplateBlockListState {
  searchTerm: string
  searchType: string
}
export default class ModContent extends React.Component<ModTemplateBlockListProps, ModTemplateBlockListState> {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      searchType: '',
    }
  }

  render() {
    const { rootState, currentLanguage, displaySize } = this.props
    const { pages } = rootState

    let currentPage
    try {
      currentPage = pages[rootState.currentPage.type][rootState.currentPage.id]
    } catch (error) {}

    let headerHtml = null
    let contentHtml = null
    let editMode = null
    let title = ''

    const onSearchTermChanged = (term: string, type: string) => {
      if (!type) type = ''
      this.setState({ searchTerm: term, searchType: type })
    }

    if (currentPage && currentPage.loadedDateTime && currentPage._pageType) {
      switch (currentPage._pageType) {
        case PageType.page:
          let page = currentPage as Page
          title = page._i18nCL.title
          headerHtml = <ModBlockHeader content={page.blockHeader} displaySize={displaySize} />
          if (page.content.templateBlockList) {
            headerHtml = <ModBlockHeader key={1} content={page.blockHeader} displaySize={displaySize} />
            contentHtml = (
              <ModTemplateBlockList
                currentLanguage={currentLanguage}
                templateData={page.content.templateBlockList}
                rootState={rootState}
              />
            )
          } else if (page.content.templateLawyerOverview) {
            headerHtml = <ModBlockHeader key={2} content={page.blockHeader} displaySize={displaySize} />

            contentHtml = (
              <>
                {page.content.templateLawyerOverview.blocks ? (
                  <ModTemplateBlockList
                    currentLanguage={currentLanguage}
                    templateData={page.content.templateLawyerOverview.blocks}
                    rootState={rootState}
                  />
                ) : (
                  ''
                )}
                <ModBlockFilter
                  key={1}
                  type={FilterType.lawyer}
                  filters={page.content.templateLawyerOverview.filters}
                  result={page.content.templateLawyerOverview.result}
                  currentLanguage={currentLanguage}
                  displaySize={displaySize}
                  pagingLength={18}
                />
              </>
            )
          } else if (page.content.templatePublicationOverview) {
            headerHtml = <ModBlockHeader key={3} content={page.blockHeader} displaySize={displaySize} />

            contentHtml = (
              <>
                {page.content.templatePublicationOverview.blocks ? (
                  <ModTemplateBlockList
                    currentLanguage={currentLanguage}
                    templateData={page.content.templatePublicationOverview.blocks}
                    rootState={rootState}
                  />
                ) : (
                  ''
                )}
                <ModBlockFilter
                  key={2}
                  type={FilterType.publication}
                  filters={page.content.templatePublicationOverview.filters}
                  result={page.content.templatePublicationOverview.result}
                  currentLanguage={currentLanguage}
                  displaySize={displaySize}
                  pagingLength={12}
                />
              </>
            )
          } else if (page.content.templateNewsOverview) {
            headerHtml = <ModBlockHeader key={4} content={page.blockHeader} displaySize={displaySize} />

            contentHtml = (
              <>
                {page.content.templateNewsOverview.blocks ? (
                  <ModTemplateBlockList
                    currentLanguage={currentLanguage}
                    templateData={page.content.templateNewsOverview.blocks}
                    rootState={rootState}
                  />
                ) : (
                  ''
                )}
                <ModBlockFilter
                  key={3}
                  type={FilterType.news}
                  filters={page.content.templateNewsOverview.filters}
                  result={page.content.templateNewsOverview.result}
                  currentLanguage={currentLanguage}
                  displaySize={displaySize}
                  pagingLength={12}
                />
              </>
            )
          } else if (page.content.templateOffice) {
            headerHtml = (
              <ModBlockHeader
                content={page.blockHeader}
                coordinates={page.content.templateOffice.office.location}
                displaySize={displaySize}
              />
            )
            contentHtml = <ModTemplateOffice content={page.content.templateOffice} />
          } else if (page.content.templateSearch) {
            headerHtml = (
              <ModBlockHeader content={page.blockHeader} onSearchTermChanged={onSearchTermChanged} displaySize={displaySize} />
            )
            contentHtml = (
              <>
                {page.content.templateSearch.blocks ? (
                  <ModTemplateBlockList
                    currentLanguage={currentLanguage}
                    templateData={page.content.templateSearch.blocks}
                    rootState={rootState}
                  />
                ) : (
                  ''
                )}
                <ModBlockSearch
                  currentLanguage={currentLanguage}
                  searchTerm={this.state.searchTerm}
                  searchType={this.state.searchType}
                />
              </>
            )
          } else if (page.content.templateDataProtection) {
            const hasNextPage = page.content.templateDataProtection.nextPage
            const hasPrevPage = page.content.templateDataProtection.prevPage

            const nextPage = { internalLink: { node: hasNextPage } }
            const prevPage = { internalLink: { node: hasPrevPage } }

            headerHtml = <ModBlockHeader key={1} content={page.blockHeader} displaySize={displaySize} />
            contentHtml = (
              <>
                <ModTemplateBlockList
                  currentLanguage={currentLanguage}
                  templateData={page.content.templateDataProtection.blocks}
                  rootState={rootState}
                />

                <ModAnimation className={'grid page-pagination align-center'}>
                  <div className={'cell small-12 large-10 xlarge-9'}>
                    {hasPrevPage ? (
                      <ModAnchorTag linkObject={prevPage} className={'link link--internal'}>
                        <FormattedMessage id="page.previousArticle" defaultMessage="Previous Article" />
                      </ModAnchorTag>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={'cell small-12 large-10 xlarge-9 text-right'}>
                    {hasNextPage ? (
                      <ModAnchorTag linkObject={nextPage} className={'link link link--prev'}>
                        <FormattedMessage id="page.nextArticle" defaultMessage="Next Article" />
                      </ModAnchorTag>
                    ) : (
                      ''
                    )}
                  </div>
                </ModAnimation>
              </>
            )
          }

          break
      }
    }

    if (rootState.editMode) {
      editMode = <ModEditBlock entityObject={currentPage} title={title} rootState={rootState} />
    }

    if (rootState.loadingState === LoadingState.offline) {
      contentHtml = (
        <main id="content" className="main loading-state">
          <img alt="offline icon" src={offlineImage} />
        </main>
      )
    } else if (!contentHtml) {
      if (rootState.loadingState === LoadingState.loading) {
        contentHtml = (
          <main id="content" className="main loading-state">
            <img alt="loading indicator" src={require('static/img/loading.gif')} />
          </main>
        )
      } else {
        contentHtml = (
          <Mod404 rootState={rootState} templateData={rootState.websiteSettings.blocks404} displaySize={displaySize} />
        )
      }
    }

    return (
      <main className={'main' + (rootState.loadingState === LoadingState.loading ? ' loading' : ' loaded')} id="content">
        {editMode}
        {headerHtml}
        {contentHtml}
      </main>
    )
  }
}
