import * as React from 'react'
import { IsomorphicURLSearchParams } from '../../common/IsomorphicURL'

import { Link, NavigationNode, PageType } from '../../interfaces/Interfaces'
import ModSvg from './ModSvg'
import NavLink from './NavLink'
import { NavigationNodeContext } from '../ModApp'
import { getCurrentLanguageOrFallBackByPath } from '../../common/Languages'
import { findNavigationNodeById } from '../../common/CmsUtils'

const IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg']

export interface ModAnchorTagProps {
  linkObject: Link
  hasIcon?: boolean
  className?: string
  linkText?: boolean
}

export default class ModAnchorTag extends React.Component<ModAnchorTagProps, {}> {
  static getLinkIconType(linkObject, hasIcon) {
    if (!hasIcon) return null
    let iconType = null
    if (linkObject.hasOwnProperty('url')) {
      // iconType = <img src={require("static/img/svg/link-external.svg")} id="icon" />
      iconType = <ModSvg icon="url" />
    } else if (linkObject.hasOwnProperty('document')) {
      iconType = <ModSvg icon="download" />
    }
    return iconType
  }

  static invalidateChildren(children) {
    if (typeof children === 'string') {
      return <span>{children}</span>
    } else if (typeof children === 'undefined') {
      return null
    }
    return children
  }

  render() {
    let children = ModAnchorTag.invalidateChildren(this.props.children)

    if (!(typeof this.props.linkObject === 'object')) {
      return children
    }

    const { linkObject, hasIcon } = this.props

    let className = ''
    if (this.props.className) {
      className = this.props.className
    }

    try {
      const linkIcon = ModAnchorTag.getLinkIconType(linkObject, hasIcon)
      if (linkObject.hasOwnProperty('url')) {
        if (!linkObject.url._i18nCL || linkObject.url._i18nCL === '') return children
        const i18nCL = linkObject.url._i18nCL
        if (i18nCL.startsWith('mailto:')) {
          return (
            <a className={'a-mail ' + className} href={i18nCL}>
              {linkIcon}
              {children}
            </a>
          )
        }

        if (this.props.linkText) {
          className = 'link link--external ' + className
        }
        var path = require('path')
        if (path.isAbsolute(i18nCL)) {
          return (
            <NavLink
              className={'a-internal ' + className}
              to={{
                pathname: i18nCL,
              }}
            >
              {linkIcon}
              {children}
            </NavLink>
          )
        } else {
          return (
            <a className={'a-external ' + className} href={i18nCL} target="_blank" rel="noopener">
              {linkIcon}
              {children}
            </a>
          )
        }
      } else if (linkObject.hasOwnProperty('internalLink')) {
        const internalLink = linkObject.internalLink

        return (
          <NavigationNodeContext.Consumer>
            {({ location, rootNavigationNode }) => {
              let navigationNode: NavigationNode
              if (typeof internalLink.node === 'string') {
                navigationNode = findNavigationNodeById(internalLink.node, rootNavigationNode)
              } else {
                navigationNode = findNavigationNodeById(internalLink.node._id, rootNavigationNode)
              }

              if (!navigationNode) {
                console.warn(`navigationNode ${internalLink.node} not found`)
                return children
              }

              if (this.props.linkText) {
                className = 'link link--internal ' + className
              }

              let url = navigationNode._i18nCL.relativeUrl
              if (internalLink.language && navigationNode.i18n && navigationNode.i18n.hasOwnProperty(internalLink.language)) {
                url = navigationNode.i18n[internalLink.language].relativeUrl
              }

              let locationTo = {
                pathname: url,
              }
              if (internalLink.queryParams) {
                let queryParams = new IsomorphicURLSearchParams()
                internalLink.queryParams.forEach((queryParam) => {
                  queryParams.append(queryParam.key, queryParam.value)
                })
                locationTo['query'] = queryParams
              }
              return (
                <NavLink className={'a-internal ' + className} to={locationTo}>
                  {linkIcon}
                  {children}
                </NavLink>
              )
            }}
          </NavigationNodeContext.Consumer>
        )
      } else if (linkObject.hasOwnProperty('staticPage')) {
        const page = linkObject.staticPage
        let relativeUrl
        let type
        if (page.hasOwnProperty('lawyer')) {
          type = 'lawyer'
        } else if (page.hasOwnProperty('expertise')) {
          type = 'expertise'
        } else if (page.hasOwnProperty('news')) {
          type = 'news'
        } else if (page.hasOwnProperty('publication')) {
          type = 'publication'
        } else {
          console.warn(`page ${linkObject.staticPage} not found`)
          return children
        }

        relativeUrl = page[type]._i18nCL.relativeUrl
        if (page.language && page[type].i18n && page[type].i18n.hasOwnProperty(page.language)) {
          relativeUrl = page[type].i18n[page.language].relativeUrl
        }

        if (this.props.linkText) {
          className = 'link link--internal ' + className
        }

        return (
          <NavLink
            className={'a-internal ' + className}
            to={{
              pathname: relativeUrl,
            }}
          >
            {linkIcon}
            {children}
          </NavLink>
        )
      } else if (linkObject.hasOwnProperty('document') && typeof this.props.linkObject.document === 'object') {
        let mediaUrl = linkObject.document.media.url
        if (linkObject.document._i18nCL && linkObject.document._i18nCL.media) {
          // use i18 media if present
          mediaUrl = linkObject.document._i18nCL.media.url
        }
        return (
          <a className={'link link--download ' + className} href={mediaUrl} target="_blank" rel="noopener">
            {linkIcon}
            {children}
          </a>
        )
      }
    } catch (e) {
      console.warn(e)
    }

    return children
  }
}
