import * as React from 'react'
import { BlockContact } from 'interfaces/Interfaces'
import ModImgTag from 'components/common/ModImgTag'
import ModAnimation from 'components/common/ModAnimation'
import ModSvg from 'components/common/ModSvg'
import { injectIntl } from 'react-intl'
import ModRichText from 'components/common/ModRichText'

export interface ModBlockContactProps {
  content: BlockContact
  intl: any
}

function ModBlockContact(props: ModBlockContactProps) {
  const { content, intl } = props

  if (!content._i18nCL) {
    return <div></div>
  }

  let getImage = null
  if (content.image) {
    getImage = <ModImgTag className="lawyer-teaser__image" imgObject={content.image} height={560} width={560} />
  } else {
    getImage = (
      <figure className="lawyer-teaser__image">
        <img src={require('static/img/_blank_square.gif')} alt="default" />
      </figure>
    )
  }

  let contactPicture = (
    <ModAnimation tag="article" className="lawyer-teaser">
      {getImage}

      <div className="lawyer-teaser__content">
        {content._i18nCL.position && (
          <>
            <span className="label lawyer-teaser__content__label">{content._i18nCL.position}</span>
          </>
        )}
        <h2 className="lawyer-teaser__content__title">
          {content._i18nCL.academicTitle} {content.name}
        </h2>
      </div>
    </ModAnimation>
  )

  let contactData = (
    <ModAnimation className="">
      <ModRichText richText={content._i18nCL.description} />
      <p>
        {content.phone && (
          <>
            {' '}
            {intl.formatMessage({ id: 'lawyer.phoneNumber', defaultMessage: 'P' }) + ': '}
            <a href={'tel:' + content.phone}>{content.phone}</a>
            <br />{' '}
          </>
        )}
        {content.mobile && (
          <>
            {'M: ' + content.mobile} <br />{' '}
          </>
        )}
        {content.email && (
          <>
            <a href={'mailto:' + content.email}>{content.email}</a>
          </>
        )}
      </p>
      {content.linkedin && (
        <>
          <a className="link link--url" href={content.linkedin}>
            <ModSvg icon={'linkedin'} /> LinkedIn
          </a>
        </>
      )}
    </ModAnimation>
  )

  return (
    <div className="block-contact grid align-center">
      <div className="cell small-24 large-20 xlarge-18">
        <div className="grid align-middle">
          <div className="cell small-24 medium-8 large-8 xxlarge-6">{contactPicture}</div>
          <div className="cell small-24 medium-14 large-14 large-offset-1 xxlarge-16">{contactData}</div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ModBlockContact)
