import * as React from 'react'
import { BlockVideo } from '../../interfaces/Interfaces'

import ModImgTag from '../common/ModImgTag'
import ModAnchorTag from '../common/ModAnchorTag'
import ModSvg from '../common/ModSvg'
import ModAnimation from '../common/ModAnimation'
import { FormattedMessage } from 'react-intl'

export interface ModBlockVideoState {
  videoOpen: boolean
}

export interface ModBlockVideoProps {
  content: BlockVideo
}

export default class ModBlockVideo extends React.Component<ModBlockVideoProps, ModBlockVideoState, {}> {
  videoIframe

  constructor(props) {
    super(props)

    this.state = {
      videoOpen: false,
    }

    this.videoIframe = React.createRef()
    this.toggleVideo = this.toggleVideo.bind(this)
    this.closeVideo = this.closeVideo.bind(this)
  }

  toggleVideo() {
    this.setState({ videoOpen: !this.state.videoOpen })
  }

  closeVideo() {
    this.setState({ videoOpen: false })
  }

  render() {
    const { content } = this.props
    const { videoOpen } = this.state

    if (!(content._i18nCL && content._i18nCL.youtubeId)) {
      return null
    }
    const i18n = content._i18nCL

    return (
      <div className="block-video">
        <div className="grid align-center">
          <ModAnimation className="cell small-24 large-20 xlarge-18">
            <div className="block-video__element" onClick={this.toggleVideo}>
              <ModImgTag className="block-video__element__image" imgObject={content.image} width={1920} height={1080} />
              <div className="block-video__element__button">
                <ModSvg icon="play" />
              </div>
            </div>
          </ModAnimation>
        </div>
        <div className={'block-video__modal ' + (videoOpen ? 'open' : 'closed')}>
          <div className="block-video__modal__video">
            <div className="cookieconsent-optout-marketing">
              <div className="cookieconsent-optout-marketing-custom">
                <p>
                  <a
                    onClick={() => {
                      // @ts-ignore
                      window.Cookiebot.show()
                    }}
                  >
                    <FormattedMessage
                      id="cookiePolicy.acceptMarketing"
                      defaultMessage="Please accept marketing cookies to see this content."
                    />
                  </a>
                </p>
              </div>
            </div>
            <iframe
              className="block-video__iframe"
              data-cookieconsent="marketing"
              ref={this.videoIframe}
              width="560"
              height="315"
              data-src={'https://www.youtube.com/embed/' + i18n.youtubeId + '?autoplay=' + (videoOpen ? 1 : 0) + '&rel=0'}
            ></iframe>
          </div>
          <div className="block-video__modal__close" onClick={this.toggleVideo}>
            <ModSvg icon="close" />
          </div>
          <div className="block-video__modal__bg" onClick={this.closeVideo}></div>
        </div>
      </div>
    )
  }
}
