import * as React from 'react'

import ModEditBlock from '../common/ModEditBlock'
import RootState from '../../vo/RootState'
import ModBlockTitle from '../blocks/ModBlockTitle'
import ModBlockLead from '../blocks/ModBlockLead'
import ModBlockRichText from '../blocks/ModBlockRichText'
import ModBlockImageText from '../blocks/ModBlockImageText'
import ModBlockImage from 'components/blocks/ModBlockImage'
import ModBlockVideo from 'components/blocks/ModBlockVideo'
import ModBlockQuote from 'components/blocks/ModBlockQuote'
import ModBlockLink from 'components/blocks/ModBlockLink'
import ModBlockTextGrid from 'components/blocks/ModBlockTextGrid'
import ModBlockImageGrid from 'components/blocks/ModBlockImageGrid'
import ModBlockInNumbers from 'components/blocks/ModBlockInNumbers'
import ModBlockExpertiseGrid from 'components/blocks/ModBlockExpertiseGrid'
import ModBlockNewsPublicationsGrid from 'components/blocks/ModBlockNewsPublicationsGrid'
import ModBlockAwards from 'components/blocks/ModBlockAwards'
import ModBlockHistory from 'components/blocks/ModBlockHistory'
import ModBlockButtons from 'components/blocks/ModBlockButtons'
import ModBlockAccordion from 'components/blocks/ModBlockAccordion'
import ModBlockSearchBox from 'components/blocks/ModBlockSearchBox'
import ModBlockTeam from 'components/blocks/ModBlockTeam'
import ModBlockIconTextGrid from 'components/blocks/ModBlockIconTextGrid'
import ModBlockTeaserGrid from 'components/blocks/ModBlockTeaserGrid'
import ModBlockContact from 'components/blocks/ModBlockContact'
import ModBlockJobIFrame from 'components/blocks/ModBlockJobIFrame'

export interface ModTemplateBlockListProps {
  rootState: RootState
  templateData: any[]
  currentLanguage: string
}

export default class ModTemplateBlockList extends React.Component<ModTemplateBlockListProps, {}> {
  render() {
    const { templateData, rootState, currentLanguage } = this.props

    const blockList = templateData.map((_item, index) => {
      const typeKey = Object.keys(_item)[0]
      const content = _item[typeKey]
      return { typeKey, content }
    })

    let html = blockList.reduce((itemList, item, index) => {
      const { typeKey, content } = item

      let blockMarkup = null
      switch (typeKey) {
        case 'blockTitle':
          blockMarkup = <ModBlockTitle key={index} content={content} />
          break
        case 'blockLead':
          blockMarkup = <ModBlockLead key={index} content={content} />
          break
        case 'blockRichText':
          blockMarkup = <ModBlockRichText key={index} content={content} />
          break
        case 'blockImageText':
          blockMarkup = <ModBlockImageText key={index} content={content} />
          break
        case 'blockImage':
          blockMarkup = <ModBlockImage key={index} content={content} />
          break
        case 'blockVideo':
          blockMarkup = <ModBlockVideo key={index} content={content} />
          break
        case 'blockQuote':
          blockMarkup = <ModBlockQuote key={index} content={content} />
          break
        case 'blockLink':
          blockMarkup = <ModBlockLink key={index} content={content} />
          break
        case 'blockTextGrid':
          blockMarkup = <ModBlockTextGrid key={index} content={content} />
          break
        case 'blockImageGrid':
          blockMarkup = <ModBlockImageGrid key={index} content={content} />
          break
        case 'blockInNumbers':
          blockMarkup = <ModBlockInNumbers key={index} content={content} />
          break
        case 'blockAwards':
          blockMarkup = <ModBlockAwards key={index} content={content} />
          break
        case 'blockHistory':
          blockMarkup = <ModBlockHistory key={index} content={content} />
          break
        case 'blockExpertiseGrid':
          blockMarkup = <ModBlockExpertiseGrid key={index} content={content} />
          break
        case 'blockPublicationsGrid':
          blockMarkup = <ModBlockNewsPublicationsGrid currentLanguage={currentLanguage} key={index} content={content} />
          break
        case 'blockNewsGrid':
          blockMarkup = <ModBlockNewsPublicationsGrid currentLanguage={currentLanguage} key={index} content={content} />
          break
        case 'blockButtons':
          blockMarkup = <ModBlockButtons key={index} content={content} />
          break
        case 'blockAccordion':
          blockMarkup = <ModBlockAccordion key={index} content={content} />
          break
        case 'blockSearch':
          blockMarkup = <ModBlockSearchBox key={index} currentLanguage={currentLanguage} content={content} />
          break
        case 'blockTeam':
          blockMarkup = <ModBlockTeam key={index} content={content} />
          break
        case 'blockIconTextGrid':
          blockMarkup = <ModBlockIconTextGrid key={index} content={content} />
          break
        case 'blockTeaserGrid':
          blockMarkup = <ModBlockTeaserGrid key={index} content={content} />
          break
        case 'blockContact':
          blockMarkup = <ModBlockContact key={index} content={content} />
          break
        case 'blockJobIFrame':
          blockMarkup = (
            <ModBlockJobIFrame key={index} content={content} baseSrc={rootState.publicConfig.jobApplicationIFrameUrl} />
          )
          break
        default:
          blockMarkup = null
      }

      if (rootState.editMode && blockMarkup) {
        itemList.push(<ModEditBlock key={'_' + index} entityObject={content} title={typeKey} rootState={rootState} />)
      }
      itemList.push(blockMarkup)
      return itemList
    }, [])

    return <div className="template-block-list">{html}</div>
  }
}
