import * as React from 'react'
import ModImgTag from '../common/ModImgTag'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Coordinates, BlockHeader } from '../../interfaces/Interfaces'
import { TimelineMax, Power4 } from 'gsap'
import ModAnchorTag from 'components/common/ModAnchorTag'
import GoogleMapReact from 'google-map-react'
import ModSvg from '../common/ModSvg'
import ModAnimation from '../common/ModAnimation'
import { NavigationNodeContext } from 'components/ModApp'
import { searchQueryParams } from 'vo/SearchQueryParams'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper'
import ModRichText from 'components/common/ModRichText'

export interface ModBlockHeaderProps {
  content: BlockHeader
  bigHeader?: boolean
  coordinates?: Coordinates
  label?: string
  displaySize: string
  onSearchTermChanged?: (string: string, type: string) => void
  intl: any
}

function ModBlockHeader(props: ModBlockHeaderProps) {
  const { SplitText } = require('../gsap-bonus/SplitText')
  const { location } = React.useContext(NavigationNodeContext)
  const { content, bigHeader, coordinates, label, onSearchTermChanged, displaySize } = props
  const hero = React.useRef(null)
  const searchInput = React.useRef(null)

  SwiperCore.use([Pagination, EffectFade, Autoplay])

  const [searchTerm, setSearchTerm] = React.useState(() => {
    if (!onSearchTermChanged) return ''
    let term = ''
    let type = ''
    if (location.query) {
      term = location.query.get(searchQueryParams.term) || ''
      type = location.query.get(searchQueryParams.type) || ''
    }
    if (term !== '') onSearchTermChanged(term, type)
    return term
  })
  React.useEffect(() => {
    setTimeout(function () {
      if (hero.current) {
        hero.current.classList.add('hero--loaded')
      }
    }, 250)

    return () => {
      if (hero.current) {
        hero.current.classList.remove('hero--loaded')
      }
    }
  }, [content._id])

  // React.useEffect(() => {
  //   setTimeout(function () {
  //     var tl = new TimelineMax({
  //         onComplete: () => {
  //           if (props.content.items.length <= 1) {
  //             mySplitTextParent.revert()
  //             mySplitText.revert()
  //           }
  //         },
  //         onStart: () => {
  //           hero.current.classList.add('hero--loaded')
  //         },
  //       }),
  //       mySplitText = new SplitText('.hero__title', { type: 'lines', linesClass: 'line-child' }),
  //       mySplitTextParent = new SplitText('.hero__title', { type: 'lines', linesClass: 'line line++' })

  //     if (props.content.items.length <= 1) {
  //       tl.staggerFrom('.line-child', 1, { y: 100, delay: 0.25, ease: Power4.easeOut }, 0.15)
  //     }
  //   }, 250)

  //   return () => {
  //     hero.current.classList.remove('hero--loaded')
  //   }
  // }, [content._id])

  React.useEffect(() => {
    if (typeof window !== 'object') {
      return
    }

    if (onSearchTermChanged) {
      searchInput.current.focus()
    }
  })

  React.useEffect(() => {
    return () => {
      //clear search terms on unmount
      if (onSearchTermChanged) {
        onSearchTermChanged('', '')
      }
    }
  }, [])

  const getSearchType = () => {
    if (location.query) {
      return location.query.get(searchQueryParams.type) || ''
    }
    return ''
  }

  const getLink = (text, link) => {
    if (onSearchTermChanged) {
      return (
        <div className={'fadeIn'}>
          <div
            className="button button--hollow"
            onClick={() => {
              onSearchTermChanged(searchTerm, getSearchType())
            }}
          >
            <FormattedMessage id="filter.search" defaultMessage="Search" />
          </div>
        </div>
      )
    } else if (!link || !text?.linkText) {
      return null
    } else {
      return (
        <div className={'fadeIn'}>
          <ModAnchorTag
            linkObject={link}
            className={'button button--hollow' + (displaySize === 'mobile' ? '' : ' button--white')}
          >
            {text.linkText}
          </ModAnchorTag>
        </div>
      )
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSearchTermChanged(searchTerm, getSearchType())
    }
  }

  const getImage = (image) => {
    if (coordinates) {
      const GetMapIcon = ({ lat, lng }) => (
        <div className="hero__image__icon">
          <ModSvg icon="location" />
        </div>
      )
      const mapOptions = {
        styles: require('../googleMap/styling.json'),
      }
      return (
        <div className="hero__image hero__image--map">
          <GoogleMapReact
            onGoogleApiLoaded={({ map, maps }) => {
              if (displaySize !== 'mobile') {
                map.panBy(map.getDiv().offsetWidth * -0.3, 0)
              }
            }}
            bootstrapURLKeys={{
              key: 'AIzaSyAiTF9nw_8YDaH40Jvkg7DqHsiklho-ZCs',
            }}
            defaultCenter={{ lat: coordinates.lat, lng: coordinates.long }}
            center={{ lat: coordinates.lat, lng: coordinates.long }}
            defaultZoom={14}
            options={mapOptions}
          >
            <GetMapIcon lat={coordinates.lat} lng={coordinates.long} />
          </GoogleMapReact>
        </div>
      )
    } else if (image === undefined || image === null) {
      return null
    } else {
      return <ModImgTag className={'hero__image'} imgObject={image} width={2568} height={1440} visible={true} />
    }
  }

  const getElements = props.content.items.map((item, index) => {
    let getLabel = null
    // if (item._i18nCL && item._i18nCL.subtitle) {
    //   getLabel = <div {'fadeIn label hero__label'}> {item._i18nCL.subtitle}</div>
    // }

    let getTitle = null
    if (item._i18nCL && item._i18nCL.text) {
      // getTitle = <h1 className={'hero__title'}>test</h1>
      getTitle = (
        // <div className={'hero__title'}>
        <ModRichText className={'fadeIn hero__label'} richText={item._i18nCL.text} />
        // </div>
      )
    }

    let getSeachInput = null
    if (onSearchTermChanged) {
      getSeachInput = (
        <ModAnimation>
          <input
            ref={(t) => (searchInput.current = t)}
            type="search"
            placeholder={props.intl.formatMessage({ id: 'filter.search', defaultMessage: 'Search' })}
            onKeyPress={handleKeyPress}
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          ></input>
        </ModAnimation>
      )
    }

    const element = (
      <div className={'hero__slide' + (getImage(item.image) ? '' : ' hero__slide--no-image')}>
        {getImage(item.image) && getImage(item.image)}
        {!getImage(item.image) && (
          <figure className="hero__image hero__image--default">
            <img src={require('static/img/_blank_square.gif')} alt="default" />
          </figure>
        )}
        <div className={'hero__content'}>
          <div>
            {getLabel}
            {getTitle}
            {getSeachInput}
            {getLink(item._i18nCL, item.link)}
          </div>
        </div>
      </div>
    )

    if (coordinates) {
      return <React.Fragment key={index}>{element}</React.Fragment>
    }

    return <SwiperSlide key={index}>{element}</SwiperSlide>
  })

  return (
    <div
      className={
        'hero' +
        (getElements.length > 1 || props.bigHeader ? ' hero--big' : ' hero--small') +
        (getElements.length > 1 ? ' hero--slider' : '')
      }
      id={'hero'}
      ref={hero}
    >
      {coordinates ? (
        getElements
      ) : (
        <Swiper
          effect="fade"
          fadeEffect={{ crossFade: true }}
          autoplay={{
            delay: 6000,
          }}
          speed={1000}
          spaceBetween={0}
          watchOverflow={true}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
        >
          {getElements}
        </Swiper>
      )}
    </div>
  )
}

export default injectIntl(ModBlockHeader)
