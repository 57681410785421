import * as React from 'react'
import { Entity, convertFromRaw } from 'draft-js'
import ModAnchorTag from 'components/common/ModAnchorTag'
import { OrderedSet, is, List } from 'immutable'

export const BLOCK_TYPE = {
  UNSTYLED: 'unstyled',
  HEADER_ONE: 'header-one',
  HEADER_TWO: 'header-two',
  HEADER_THREE: 'header-three',
  HEADER_FOUR: 'header-four',
  HEADER_FIVE: 'header-five',
  HEADER_SIX: 'header-six',
  UNORDERED_LIST_ITEM: 'unordered-list-item',
  ORDERED_LIST_ITEM: 'ordered-list-item',
  BLOCKQUOTE: 'blockquote',
  PULLQUOTE: 'pullquote',
  CODE: 'code-block',
  ATOMIC: 'atomic',
  LINK_BUTTON: 'link-button',
  HR: 'hr',
  COOKIE_CONSENT: 'cookie-consent',
}

const ENTITY_TYPE = {
  LINK: 'LINK',
  IMAGE: 'IMAGE',
  HORIZONTAL_RULER: 'HORIZONTAL_RULER',
  NAVIGATION_NODE: 'navigationNode',
  OVERLAY: 'overlay',
  DOCUMENT: 'document',
  URL: 'url',
}

const INLINE_STYLE = {
  BOLD: 'BOLD',
  CODE: 'CODE',
  ITALIC: 'ITALIC',
  STRIKETHROUGH: 'STRIKETHROUGH',
  UNDERLINE: 'UNDERLINE',
}

const DEFAULT_STYLE_ORDER = [
  INLINE_STYLE.BOLD,
  INLINE_STYLE.ITALIC,
  INLINE_STYLE.UNDERLINE,
  INLINE_STYLE.STRIKETHROUGH,
  INLINE_STYLE.CODE,
]

interface CookiebotConsentScriptProps {
  children?: React.ReactNode
}

const CookiebotConsentScript = ({children}: CookiebotConsentScriptProps) => {
  // NOTE: bär & karrer only wants a simple link to re-open the bookie consent banner
  //  the code below enables the full cookie declaration with tables and all, re-enable if needed.

  // const elementRef = React.useRef<HTMLDivElement>(null)

  // React.useEffect(() => {
  //   const cookiebotScriptElement = document.querySelector('#Cookiebot')

  //   if (cookiebotScriptElement && elementRef.current) {
  //     const cookiebotId = cookiebotScriptElement.getAttribute('data-cbid')
  //     const scriptElement = document.createElement('script')

  //     scriptElement.setAttribute('id', 'CookieDeclaration')
  //     scriptElement.setAttribute('src', `https://consent.cookiebot.com/${cookiebotId}/cd.js`)
  //     scriptElement.setAttribute('type', 'text/javascript')
  //     scriptElement.setAttribute('async', '')

  //     elementRef.current.appendChild(scriptElement)

  //     return () => {
  //       elementRef.current.removeChild(scriptElement)
  //     }
  //   }
  // }, [])

  // return <div ref={elementRef} />

  return (
    <p>
      <a style={{cursor: "pointer"}} aria-role="button" onClick={() => (window as any).Cookiebot.renew()}>{children}</a>
    </p>
  )
}

export function createReactComponents(rawState, className) {
  let mergedBlock = null
  const mergedBlocks = rawState.blocks.reduce((previousValue, block, index, array) => {
    const text = block.text
    const blockType = block.type
    const data = block.data
    const inlineStyleRanges = block.inlineStyleRanges
    const entityRanges = block.entityRanges

    if (blockType === BLOCK_TYPE.UNSTYLED || blockType == undefined) {
      if (mergedBlock == null) {
        if (text !== '') {
          mergedBlock = { ...block }
        }
      } else {
        if (text !== '') {
          const textLength = mergedBlock.text.length + 1

          mergedBlock.text = `${mergedBlock.text}\n${text}`
          mergedBlock.data = { ...mergedBlock.data, ...data }
          mergedBlock.inlineStyleRanges = [
            ...mergedBlock.inlineStyleRanges,
            ...inlineStyleRanges.map((item) => ({ ...item, offset: item.offset + textLength })),
          ]
          mergedBlock.entityRanges = [
            ...mergedBlock.entityRanges,
            ...entityRanges.map((item) => ({ ...item, offset: item.offset + textLength })),
          ]
        } else {
          previousValue.push(mergedBlock)
          mergedBlock = null
        }
      }
    } else {
      if (mergedBlock != null) {
        previousValue.push(mergedBlock)
        mergedBlock = null
      }
      previousValue.push(block)
    }

    return previousValue
  }, [])

  if (mergedBlock != null) {
    mergedBlocks.push(mergedBlock)
    mergedBlock = null
  }

  const contentState = convertFromRaw({ ...rawState, blocks: mergedBlocks })

  let blocks = contentState.getBlocksAsArray()

  let lastText = null
  let renderedBlocks = blocks.reduce(
    (previousValue, block, index, array) => {
      let blockType = block.getType()
      let blockData = block.getData()
      let key = block.getKey()
      let text = block.getText()
      if (text === '') {
        if (blockType === BLOCK_TYPE.HR) {
          previousValue.allBlocks.push(<hr key={index} />)
          return previousValue
        }
        if (previousValue.nesting) {
          nestLists(previousValue, index)
        }

        if (lastText === '') {
          // Prevent element collapse if completely empty.
          previousValue.allBlocks.push(<br key={index} />)
        }

        lastText = block.getText()

        return previousValue
      }

      lastText = block.getText()

      let charMetaList = block.getCharacterList()
      let entityPieces = getEntityRanges(text, charMetaList)
      let content = entityPieces.map(([entityKey, stylePieces], indexPiece) => {
        let styledContent = stylePieces.map(([text, styleSet], indexStyle) => {
          let k = index + '_' + indexPiece + '_' + indexStyle

          if (text === '\n') {
            return <br key={k} />
          }

          let contentPiece = text.split('\n').map((item, index) =>
            index === 0 ? (
              item
            ) : (
              <React.Fragment key={index}>
                <br />
                {item}
              </React.Fragment>
            )
          )

          for (let styleName of DEFAULT_STYLE_ORDER) {
            if (styleSet.has(styleName)) {
              switch (styleName) {
                case INLINE_STYLE.BOLD:
                  contentPiece = <strong key={k}>{contentPiece}</strong>
                  break
                case INLINE_STYLE.CODE:
                  contentPiece = <code key={k}>{contentPiece}</code>
                  break
                case INLINE_STYLE.ITALIC:
                  contentPiece = <em key={k}>{contentPiece}</em>
                  break
                case INLINE_STYLE.STRIKETHROUGH:
                  contentPiece = <del key={k}>{contentPiece}</del>
                  break
                case INLINE_STYLE.UNDERLINE:
                  contentPiece = <ins key={k}>{contentPiece}</ins>
                  break
              }
            }
          }
          return contentPiece
        })

        let entity = entityKey ? contentState.getEntity(entityKey) : null
        let entityType = entity === null ? null : entity.getType()
        if (
          entityType !== null &&
          (entityType === ENTITY_TYPE.LINK ||
            entityType === ENTITY_TYPE.NAVIGATION_NODE ||
            entityType === ENTITY_TYPE.URL ||
            entityType === ENTITY_TYPE.OVERLAY ||
            entityType === ENTITY_TYPE.DOCUMENT)
        ) {
          styledContent = (
            <ModAnchorTag key={indexPiece} linkObject={entity.getData()}>
              {styledContent}
            </ModAnchorTag>
          )
        } else if (entityType !== null && entityType === ENTITY_TYPE.HORIZONTAL_RULER) {
          styledContent = <hr key={indexPiece} />
        } else if (entityType !== null && entityType === ENTITY_TYPE.IMAGE) {
          // TODO
        }
        return styledContent
      })

      if (previousValue.nesting && previousValue.nesting.type !== blockType) {
        nestLists(previousValue, index)
      }

      let contentMarkup
      switch (blockType) {
        case BLOCK_TYPE.UNORDERED_LIST_ITEM:
        case BLOCK_TYPE.ORDERED_LIST_ITEM:
          if (previousValue.nesting === null) {
            previousValue.nesting = {
              type: blockType,
              list: [],
            }
          }
          previousValue.nesting.list.push(<li key={key}>{content}</li>)
          if (index === array.length - 1) {
            nestLists(previousValue, index)
          }
          return previousValue
        case BLOCK_TYPE.HEADER_ONE:
          contentMarkup = <h1 key={key}>{content}</h1>
          break
        case BLOCK_TYPE.HEADER_TWO:
          contentMarkup = <h2 key={key}>{content}</h2>
          break
        case BLOCK_TYPE.HEADER_THREE:
          contentMarkup = <h3 key={key}>{content}</h3>
          break
        case BLOCK_TYPE.HEADER_FOUR:
          contentMarkup = <h4 key={key}>{content}</h4>
          break
        case BLOCK_TYPE.HEADER_FIVE:
          contentMarkup = <h5 key={key}>{content}</h5>
          break
        case BLOCK_TYPE.HEADER_SIX:
          contentMarkup = <h6 key={key}>{content}</h6>
          break
        case BLOCK_TYPE.BLOCKQUOTE:
          contentMarkup = <blockquote key={key}>{content}</blockquote>
          break
        case BLOCK_TYPE.CODE:
          contentMarkup = (
            <pre key={key}>
              <code>{content}</code>
            </pre>
          )
          break
        case BLOCK_TYPE.ATOMIC:
          contentMarkup = <figure key={key}>{content}</figure>
          break
        case BLOCK_TYPE.LINK_BUTTON:
          contentMarkup = (
            <div key={key} className="link-button">
              <ModAnchorTag linkObject={blockData.get('link')} hasIcon={true}>
                {content}
              </ModAnchorTag>
            </div>
          )
          break
        case BLOCK_TYPE.COOKIE_CONSENT:
          contentMarkup = <CookiebotConsentScript />
          break

        default:
          contentMarkup = <p key={key}>{content}</p>
      }

      previousValue.allBlocks.push(contentMarkup)
      return previousValue
    },
    { allBlocks: [], nesting: null }
  )

  return <div className={'rich-text ' + className}>{renderedBlocks.allBlocks}</div>
}

export const EMPTY_SET = OrderedSet() // Style

/**
 *
 * @param text string
 * @param charMetaList CharacterMetaList
 * @returns Array
 */
export function getEntityRanges(text, charMetaList) {
  let charEntity = null
  let prevCharEntity = null
  let ranges = []
  let rangeStart = 0
  for (let i = 0, len = text.length; i < len; i++) {
    prevCharEntity = charEntity
    let meta = charMetaList.get(i)
    charEntity = meta ? meta.getEntity() : null
    if (i > 0 && charEntity !== prevCharEntity) {
      ranges.push([prevCharEntity, getStyleRanges(text.slice(rangeStart, i), charMetaList.slice(rangeStart, i))])
      rangeStart = i
    }
  }
  ranges.push([charEntity, getStyleRanges(text.slice(rangeStart), charMetaList.slice(rangeStart))])
  return ranges
}

/**
 * Handle ol & ul
 * @param previousValue
 * @param index
 */
function nestLists(previousValue, index) {
  let list = previousValue.nesting.list
  if (previousValue.nesting.type === BLOCK_TYPE.UNORDERED_LIST_ITEM) {
    list = <ul key={'ul' + index}>{list}</ul>
    previousValue.allBlocks.push(list)
  } else if (previousValue.nesting.type === BLOCK_TYPE.ORDERED_LIST_ITEM) {
    list = <ol key={'ol' + index}>{list}</ol>
    previousValue.allBlocks.push(list)
  }
  previousValue.nesting = null
}

/**
 *
 * @param text string
 * @param charMetaList CharacterMetaList
 * @returns Array
 */
function getStyleRanges(text, charMetaList) {
  let charStyle = EMPTY_SET
  let prevCharStyle = EMPTY_SET
  let ranges = []
  let rangeStart = 0
  for (let i = 0, len = text.length; i < len; i++) {
    prevCharStyle = charStyle
    let meta = charMetaList.get(i)
    charStyle = meta ? meta.getStyle() : EMPTY_SET
    if (i > 0 && !is(charStyle, prevCharStyle)) {
      ranges.push([text.slice(rangeStart, i), prevCharStyle])
      rangeStart = i
    }
  }
  ranges.push([text.slice(rangeStart), charStyle])
  return ranges
}
