import * as docCookies from 'mozilla-doc-cookies'

export const goldenRatio = 1.61803399

export function getGoldenRationByWidth(width: number) {
  return Math.round(width / goldenRatio)
}

export function isInt(data) {
  return data === parseInt(data, 10)
}

export function isEmptyObject(data) {
  return JSON.stringify(data) === '{}'
}

export function draftJsHasContent(obj) {
  return obj && obj.blocks && obj.blocks.length > 0 && obj.blocks[0].text
}

/**
 *
 * @param property
 * @returns {boolean}
 */
export function hasContent(property: any) {
  if (typeof property === 'string') {
    return property !== ''
  }

  if (property instanceof Array) {
    return property.length > 0
  }

  if (property instanceof Object) {
    for (let key in property) {
      if (property.hasOwnProperty(key)) return true
    }
  }

  return false
}

/**
 *
 * @param element
 * @param className
 */
export function addClassToElement(element, className) {
  if (element.classList) element.classList.add(className)
  else element.className += ' ' + className
}

/**
 *
 * @param element
 * @param className
 */
export function removeClassFromElement(element, className) {
  if (element.classList) element.classList.remove(className)
  else
    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
}

export function aspectRationToPercentHeight(aspectRatio: string): string {
  let wh = aspectRatio.split(':')
  const result = (Number.parseFloat(wh[1]) / Number.parseFloat(wh[0])) * 100
  if (isNaN(result) || result <= 0) {
    throw new Error('wrong aspect ratio')
  }

  return result.toPrecision(5)
}

/**
 * removes slashes at the beginning and end of a string
 * @param value
 * @returns {string}
 */
export function stripSlashes(value: string) {
  if (typeof value !== 'string') return value
  return value.replace(/^\//, '').replace(/\/$/, '')
}

/**
 * If media is a mediaId string this function searches the media object in the mediaPool and returns this object.
 * otherwise the function just returns the media param
 * @param mediaPool
 * @param media might be an mediaId string or a media object
 * @returns {*}
 */
export function getMediaObjectById(mediaPool, media) {
  if (typeof media === 'string' && mediaPool && mediaPool.hasOwnProperty(media)) {
    let resultMedia = mediaPool[media]
    resultMedia.id = media
    return resultMedia
  }
  return media
}

export function addCloudinaryParamsToUrl(url: string, cloudinaryParams: string[]) {
  return url.replace(/\/(v\d+)/, '/' + cloudinaryParams.join(','))
}

/**
 * Checks if the initial request was https that a client used to connect to a proxy or load balancer
 */
export function isHttps(req): boolean {
  return req.headers['x-forwarded-proto'] === 'https'
}

/**
 * returns a random number between the specified values.
 */
export function getRandomArbitrary(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

/**
 * Randomize array element order
 */
export function shuffleArray(array: Array<any>) {
  return array.sort(function () {
    return 0.5 - Math.random()
  })
}

export function getJsonCookie(cookieName: string) {
  if (typeof window !== 'undefined' && cookieName) {
    const object = docCookies.getItem(cookieName)
    if (object) {
      return JSON.parse(object)
    }
  }
  return null
}

export function setJsonCookie(cookieName, json) {
  if (json && typeof window !== 'undefined') {
    docCookies.setItem(cookieName, JSON.stringify(json), Infinity, '/')
  }
}

export function getCookieObject() {
  const cookies: Record<string, any> = {}
  const keys = docCookies.keys()

  for (const key of keys) {
    //please do not crash on non json cookies
    try {
      cookies[key] = getJsonCookie(key)
    } catch (e) {}
  }

  return cookies
}

/**
 * magically pulls the correct lang objects out of i18n maps and stores them in __i18n_current_lang___ in the same struct.
 * @param obj
 * @param currentLanguage
 */
export function addCurrentLangRecursive(obj: object, currentLanguage: string) {
  if (!(typeof obj === 'object' && currentLanguage)) {
    return
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let item = obj[key]
      if (key === 'i18n') {
        obj['_i18nCL'] = item.hasOwnProperty(currentLanguage) ? item[currentLanguage] : null
      }
      if (item instanceof Array) {
        addCurrentLangRecursive(item, currentLanguage)
      }
      if (typeof item === 'object') {
        addCurrentLangRecursive(item, currentLanguage)
      }
    }
  }
}

/**
 *
 * @param array
 * @param sortKey
 * @returns {*}
 */
export function sortBy(array: any[], sortKey: string, prop = null, desc = true) {
  return array.sort((a, b) => {
    let nameA = prop ? a[sortKey][prop] : a[sortKey]
    let nameB = prop ? b[sortKey][prop] : b[sortKey]

    if (typeof nameA === 'string' && typeof nameB === 'string') {
      nameA = nameA.toUpperCase()
      nameB = nameB.toUpperCase()
    }
    if (nameA < nameB) {
      return desc ? 1 : -1
    }
    if (nameA > nameB) {
      return desc ? -1 : 1
    }
    return 0
  })
}

export function sortByOrder(arr: any[], order: string[], prop: string, secondProps?: string) {
  const leaf = (obj, path) => path.split('.').reduce((value, el) => value[el], obj)

  arr.sort((a, b) => {
    const propA = leaf(a, prop)
    const propB = leaf(b, prop)

    if (propA === propB) {
      const secondPropA = leaf(a, secondProps)
      const secondPropB = leaf(b, secondProps)

      if (secondPropA > secondPropB) {
        return 1
      } else {
        return -1
      }
    }

    if (order.indexOf(propA) > order.indexOf(propB)) {
      return 1
    } else {
      return -1
    }
  })
  return arr
}

export function findDuplicates(array1, array2) {
  let array1Length = array1.length
  let array2Length = array2.length

  const smallerArray = array1Length < array2Length ? array1 : array2
  const largerArray = array1Length > array2Length ? array1 : array2

  const duplicates = smallerArray.filter((val) => {
    return largerArray.indexOf(val) != -1
  })

  return duplicates
}

export function defaultDynamicImports(moduleWrapper) {
  return moduleWrapper.default
}

/**
 * Check if RichText has Content
 */
export function hasRichTextContent(richText) {
  return !(richText.blocks.length === 1 && !hasContent(richText.blocks[0].text))
}

export async function searchTermCaracterReplace(searchTerm: string) {
  searchTerm = searchTerm.toLowerCase()

  const searchTermArray = searchTerm.split('')
  let i = 0
  while (i < searchTerm.length) {
    switch (searchTermArray[i]) {
      case 'a':
        if (i + 1 < searchTerm.length) {
          if (searchTermArray[i + 1] === 'e') {
            searchTermArray.splice(i + 1, 1)
          }
        }
        searchTermArray[i] = '(a|(ae)|ä|à|á|â|ã|å)'
        break

      case 'ä':
        searchTermArray[i] = '(a|(ae)|ä|à|á|â|ã|å)'
        break

      case 'o':
        if (i + 1 < searchTerm.length) {
          if (searchTermArray[i + 1] === 'e') {
            searchTermArray.splice(i + 1, 1)
          }
        }
        searchTermArray[i] = '(o|(oe)|ö|ò|ó|ô|õ)'
        break

      case 'ö':
        searchTermArray[i] = '(o|(oe)|ö|ò|ó|ô|õ)'
        break

      case 'u':
        if (i + 1 < searchTerm.length) {
          if (searchTermArray[i + 1] === 'e') {
            searchTermArray.splice(i + 1, 1)
          }
        }

        searchTermArray[i] = '(u|(ue)|ü|ù|ú|û)'
        break

      case 'ü':
        searchTermArray[i] = '(u|(ue)|ü|ù|ú|û)'
        break

      case 'ß':
        searchTermArray[i] = '(ss)'
        break

      case 'c':
      case 'ç':
        searchTermArray[i] = '(c|ç)'
        break

      case 'e':
      case 'é':
      case 'ê':
      case 'è':
      case 'ë':
        searchTermArray[i] = '(e|é|ê|è|ë)'
        break

      case 'i':
      case 'ì':
      case 'í':
      case 'î':
      case 'ï':
        searchTermArray[i] = '(i|ì|í|î|ï)'
        break

      case 'n':
      case 'ñ':
        searchTermArray[i] = '(n|ñ)'
        break

      case 'y':
      case 'ÿ':
      case 'ý':
        searchTermArray[i] = '(y|ÿ|ý)'
        break
    }

    i++
  }

  searchTerm = searchTermArray.join('')

  return searchTerm
}
