// import * as React from 'react'
// import { BlockNewsPublicationsGrid, BlockNewsPublicationsGridType, BlockTeaserGrid } from '../../interfaces/Interfaces'
// import ModNewsPublicationsTeaser from '../common/ModNewsPublicationsTeaser'
// import ModAnchorTag from 'components/common/ModAnchorTag'
// import ModAnimation from 'components/common/ModAnimation'
// import ModBlockTitle from './ModBlockTitle'
// import { createBlockTitleObject } from 'common/CmsUtils'
// import { FormattedMessage } from 'react-intl'
// import { geti18nClIfMissing } from 'components/common/Utils'
// import ModTeaser from 'components/common/ModTeaser'

// export interface ModBlockTeaserGridProps {
//   content: BlockTeaserGrid
// }

// export default class ModBlockTeaserGrid extends React.Component<ModBlockTeaserGridProps, {}> {
//   render() {
//     let { content } = this.props
//     let category = null

//     if (content.items.length == 0) {
//       return null
//     }

//     const gridElements = content.items.map((item, index) => {
//       if (item.teaser) {
//         return (
//           // <div className="cell small-24 medium-12 large-12 xlarge-8" key={index}>
//           //   <ModTeaser content={item.teaser} />
//           // </div>
//           <ModAnimation className="block-image-grid__element cell small-24 medium-12 large-12 xlarge-8" key={index}>
//             <ModTeaser content={item.teaser} />
//           </ModAnimation>
//         )
//       } else {
//         return null
//       }
//     })

//     return (
//       <div className="block-teaser-grid">
//         <div className="grid align-center">
//           <div className="cell small-24">
//             <div className="grid news-publications">{gridElements}</div>
//             {/* <div className="grid">
//               <ModAnimation className="cell small-24 text-center">
//                 <ModAnchorTag linkObject={content.link} className={'button button--hollow'}>
//                   <FormattedMessage id="filter.all" defaultMessage="All" />{' '}
//                   {content.moreLinkText ? content.moreLinkText : category}
//                 </ModAnchorTag>
//               </ModAnimation>
//             </div> */}
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

import ModTeaser from 'components/common/ModTeaser'
import * as React from 'react'
import { BlockTeaserGrid } from '../../interfaces/Interfaces'
import ModAnimation from '../common/ModAnimation'

export interface ModBlockTeaserGridProps {
  content: BlockTeaserGrid
}

export default class ModBlockTeaserGrid extends React.Component<ModBlockTeaserGridProps, {}> {
  render() {
    //   const { content } = this.props

    //   const gridElements = content.items.map((item, index) => {
    //     if (item.teaser) {
    //       return (
    //         <ModAnimation className="block-image-grid__element cell small-24 medium-12 large-12" key={index}>
    //           <ModBlockTeaser content={item.teaser} />
    //         </ModAnimation>
    //       )
    //     } else {
    //       return null
    //     }
    //   })

    //   return (
    //     <div className="block-image-grid grid align-center">
    //       <div className="cell small-24 large-20 xlarge-18">
    //         <div className="grid">{gridElements}</div>
    //       </div>
    //     </div>
    //   )
    // }
    const { content } = this.props

    const gridElements = content.items.map((item, index) => {
      if (item.teaser) {
        return (
          <ModAnimation
            className="cell small-24 medium-8 large-8 xlarge-8"
            // className={'block-text-grid__element cell small-24 medium-12 large-11 ' + (index % 2 ? 'large-offset-2' : '')}
            key={index}
          >
            <ModTeaser content={item.teaser} />
          </ModAnimation>
        )
      } else {
        return null
      }
    })

    return (
      <div className="block-teaser grid align-center">
        <div className="cell small-24">
          <div className="grid align-center">{gridElements}</div>
        </div>
      </div>
      // <div className="block-text-grid grid align-center">
      //   <div className="cell small-24 large-20 xlarge-18">
      //     <div className="grid">{gridElements}</div>
      //   </div>
      // </div>
    )
  }
}
