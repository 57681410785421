// import moment = require('moment')
// import * as React from 'react'
// import {
//   News,
//   Publication,
//   BlockNewsPublicationsGridType,
//   NewsPublication,
//   NewsCategory,
//   PublicationCategory,
//   PageType,
//   BlockTeaser,
// } from 'interfaces/Interfaces'
// import ModRichText from './ModRichText'
// import ModAnchorTag from './ModAnchorTag'
// import ModAnimation from './ModAnimation'
// import { createStaticPageLinkObject } from 'common/CmsUtils'
// import ModImgTag from './ModImgTag'
// import { getPublicationCategoryTranslationKey, getNewsCategoryTranslationKey } from './Utils'
// import { FormattedMessage } from 'react-intl'

// export interface ModTeaserProps {
//   content: BlockTeaser
// }

// export default class ModTeaser extends React.Component<ModTeaserProps, {}> {
//   render() {
//     const { content } = this.props

//     if (!(content._i18nCL && content._i18nCL.title)) {
//       return null
//     }

//     const i18n = content._i18nCL

//     return (
//       <ModAnimation tag="article">
//         <div>
//           <ModAnchorTag linkObject={content.link}>
//             <header className={'news-publications__teaser__header'}>
//               <ModImgTag className={'news-publications__teaser__image'} imgObject={content.image} width={960} height={600} />
//             </header>
//           </ModAnchorTag>

//           <h3>{i18n.title}</h3>
//           <div className={'news-publications__teaser__content'}>
//             <ModRichText richText={i18n.text} />
//           </div>
//         </div>

//         <div className="news-publications__teaser__footer">
//           <div className="news-publications__teaser__footer__link">
//             <ModAnchorTag linkObject={content.link} linkText={true}>
//               <FormattedMessage id="global.read-more" defaultMessage="Read more" />
//             </ModAnchorTag>
//           </div>
//         </div>
//       </ModAnimation>
//     )
//   }
// }

import * as React from 'react'
import { BlockImageText, BlockTeaser } from '../../interfaces/Interfaces'
import ModRichText from './ModRichText'
import ModImgTag from './ModImgTag'
import ModAnimation from './ModAnimation'
import ModAnchorTag from './ModAnchorTag'
import { FormattedMessage } from 'react-intl'

export interface ModTeaserProps {
  content: BlockTeaser
}

export default class ModTeaser extends React.Component<ModTeaserProps, {}> {
  render() {
    const { content } = this.props

    if (!content._i18nCL) {
      return null
    }
    const i18n = content._i18nCL

    let link = null
    if (content.link) {
      link = (
        <div className="news-publications__teaser__footer">
          <ModAnchorTag linkObject={content.link} linkText={true}>
            <FormattedMessage id="global.read-more" defaultMessage="Read more" />
          </ModAnchorTag>
        </div>
        // <ModAnchorTag linkObject={content.link} linkText={true} hasIcon={true}>
        //   {i18n.linkText}
        // </ModAnchorTag>
      )
    }

    return (
      <React.Fragment>
        <ModImgTag imgObject={content.image} width={960} height={600} transformation={'c_fill'} />
        <h3>{i18n.title}</h3>
        <ModRichText richText={i18n.text} />
        {link}
      </React.Fragment>
      //  <div className="cell small-24 medium-20 large-8 large-offset-1">
      //   <ModAnimation className="block-image-text__content">

      //   </ModAnimation>
      // </div>
    )
  }
}
