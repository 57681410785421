import * as React from 'react'
import { injectIntl } from 'react-intl'
import { BlockQuote } from '../../interfaces/Interfaces'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, EffectFade } from 'swiper'
import ModAnimation from '../common/ModAnimation'

export interface ModBlockQuoteProps {
  content: BlockQuote
}

function ModBlockQuote(props: ModBlockQuoteProps) {
  const { content } = props
  SwiperCore.use([EffectFade, Autoplay])

  if (!content) {
    return null
  }

  const quotes = content.quotes.map((item, index) => {
    if (item._i18nCL && item._i18nCL.quote) {
      return (
        <SwiperSlide className="swiper-slide" key={index}>
          <blockquote className={item.type}>
            <p>{item._i18nCL.quote}</p>
            <footer>
              <span>{item._i18nCL.source}</span>
            </footer>
          </blockquote>
        </SwiperSlide>
      )
    } else {
      return null
    }
  })

  return (
    <ModAnimation tag="div" className="block-quote grid">
      <div className="cell small-24 medium-16 large-12">
        <Swiper
          effect="fade"
          fadeEffect={{ crossFade: true }}
          autoplay={{
            delay: 10000,
          }}
          spaceBetween={0}
          loop={true}
          slidesPerView={1}
        >
          {quotes}
        </Swiper>
      </div>
    </ModAnimation>
  )
}

export default injectIntl(ModBlockQuote)
