import * as React from 'react'

import { BlockRichText } from '../../interfaces/Interfaces'
import { draftJsHasContent } from '../../common/Utils'
import ModRichText from '../common/ModRichText'
import ModAnimation from '../common/ModAnimation'

export interface ModBlockRichTextProps {
  content: BlockRichText
  className?: string
}

export default class ModBlockRichText extends React.Component<ModBlockRichTextProps, {}> {
  render() {
    const { content, className } = this.props

    if (!(content._i18nCL && draftJsHasContent(content._i18nCL.text))) {
      return null
    }
    const i18n = content._i18nCL

    return (
      <div className={className ? `${className} grid align-center` : `block-rich-text grid align-center`}>
        <ModAnimation className={className ? 'cell' : 'cell small-24 large-20 xlarge-18'}>
          <div className={`background-${content.background}`}>
            <ModRichText richText={i18n.text} />
          </div>
        </ModAnimation>
      </div>
    )
  }
}
