import * as React from 'react'
import { BlockTextGrid } from "../../interfaces/Interfaces";
import ModRichText from "../common/ModRichText";
import ModAnimation from "../common/ModAnimation";

export interface ModBlockTextGridProps {
  content: BlockTextGrid
}

export default class ModBlockTextGrid extends React.Component<ModBlockTextGridProps, {}> {


  render() {
    const { content } = this.props

    const gridElements = content.items.map((item, index) => {
      if(item._i18nCL){
        return (
          <ModAnimation className={"block-text-grid__element cell small-24 medium-12 large-11 " + (index%2 ? 'large-offset-2':'')} key={index}>
            <span className="label">{item._i18nCL.title}</span>
            <ModRichText richText={item._i18nCL.text} />
          </ModAnimation>
        )
      }
      else{
        return null
      }

    })

    return (
      <div className="block-text-grid grid align-center">
        <div className="cell small-24 large-20 xlarge-18">
          <div className="grid">
            {gridElements}
         </div>
        </div>
      </div>
    )
  }
}