import * as React from 'react'
import { BlockTitle } from '../../interfaces/Interfaces'
import ModAnimation from '../common/ModAnimation'

export interface ModBlockTitleProps {
  content: BlockTitle
}

export default class ModBlockTitle extends React.Component<ModBlockTitleProps, {}> {
  splitLines
  title

  constructor(props) {
    super(props)
    this.createSplites = this.createSplites.bind(this)
    this.splitLines = null
    this.title = React.createRef()
  }

  componentDidMount() {
    const { SplitText } = require('../gsap-bonus/SplitText')
    if (this.props.content._i18nCL && this.props.content._i18nCL.title) {
      const splitLines = new SplitText(this.title, {
        type: 'lines, words',
        linesClass: 'line line++',
        wordsClass: 'word',
      })
    }
  }

  componentDidUpdate() {
    if (this.props.content._i18nCL && this.props.content._i18nCL.title) {
      this.createSplites()
    }
  }

  componentWillUnmount() {
    if (this.props.content._i18nCL && this.props.content._i18nCL.title) {
      window.removeEventListener('resize', this.createSplites)
    }
  }

  createSplites() {
    if (this.props.content._i18nCL && this.props.content._i18nCL.title) {
      if (this.splitLines !== null) {
        this.splitLines.split()
      }
    }
  }

  render() {
    const { content } = this.props

    if (!(content._i18nCL && content._i18nCL.title)) {
      return null
    }

    return (
      <div className="block-title grid align-center">
        <div className="cell small-24 medium-20 large-18 xlarge-16">
          <ModAnimation type="headline">
            <h2 className="block-title__title" ref={(title) => (this.title = title)}>
              {content._i18nCL.title}
            </h2>
          </ModAnimation>
        </div>
      </div>
    )
  }
}
