import * as React from 'react'

import { BlockLead, Office, TemplateOffice, PageType } from '../../interfaces/Interfaces'
import ModBlockLead from 'components/blocks/ModBlockLead'
import ModAnchorTag from 'components/common/ModAnchorTag'
import { createStaticPageLinkObject } from 'common/CmsUtils'
import ModAnimation from 'components/common/ModAnimation'
import ModExpertiseTeaser from 'components/common/ModExpertiseTeaser'
import { FormattedMessage } from 'react-intl'

export interface ModTemplateOfficeProps {
  content: TemplateOffice
}

export default class ModTemplateOffice extends React.Component<ModTemplateOfficeProps> {
  render() {
    const { content } = this.props

    // const getPractiseAreas = () => {
    //   if (content.practices.length > 0) {
    //     return (
    //       <div className="grid office__expertise align-center">
    //         <div className="cell small-24 large-20 xlarge-18">
    //           <ModAnimation>
    //             <span className="label">
    //               <FormattedMessage id="global.practice-areas" defaultMessage="Practice Areas" />
    //             </span>
    //           </ModAnimation>
    //           <div className={'grid expertise expertise--practices'}>{practiseAreas}</div>
    //         </div>
    //       </div>
    //     )
    //   } else {
    //     return null
    //   }
    // }

    // const practiseAreas = content.practices.map((item, index) => {
    //   if (item._i18nCL && item._i18nCL.title) {
    //     return (
    //       <ModAnimation className="cell small-24 medium-12 large-8" key={index}>
    //         <ModExpertiseTeaser content={item} />
    //       </ModAnimation>
    //     );
    //   } else {
    //     return null;
    //   }
    // });

    // const getSectors = () => {
    //   if (content.sectors.length > 0) {
    //     return (
    //       <div className="small-gap grid office__expertise align-center">
    //         <div className="cell small-24 large-20 xlarge-18">
    //           <ModAnimation>
    //             <span className="label">
    //               <FormattedMessage id="global.sectors" defaultMessage="Sectors" />
    //             </span>
    //           </ModAnimation>
    //           <div className={'grid expertise expertise--sector'}>{sectors}</div>
    //         </div>
    //       </div>
    //     )
    //   } else {
    //     return null
    //   }
    // }

    // const sectors = content.sectors.map((item, index) => {
    //   if (item._i18nCL && item._i18nCL.title) {
    //     return (
    //       <ModAnimation className="cell small-24 medium-12 large-8" key={index}>
    //         <ModExpertiseTeaser content={item} />
    //       </ModAnimation>
    //     );
    //   } else {
    //     return null;
    //   }
    // });

    const getContactInfo = () => {
      if (content.office._i18nCL) {
        return (
          <div className="grid align-center office__address">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <p>
                {content.office._i18nCL.address.name && (
                  <>
                    {' '}
                    <strong>{content.office._i18nCL.address.name}</strong>
                    <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.street && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.street} <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.city && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.city} <br />{' '}
                  </>
                )}
                {content.office._i18nCL.address.country && (
                  <>
                    {' '}
                    {content.office._i18nCL.address.country} <br />{' '}
                  </>
                )}
                {content.office.phone && (
                  <>
                    {' '}
                    {'P: '}
                    <a href={'tel:' + content.office.phone}>{content.office.phone}</a>
                    <br />{' '}
                  </>
                )}
                {content.office.fax && (
                  <>
                    {' '}
                    {'F: ' + content.office.fax} <br />{' '}
                  </>
                )}
                {content.office.email && (
                  <>
                    {' '}
                    <a href={'mailto:' + content.office.email}> {content.office.email} </a>{' '}
                  </>
                )}
              </p>
            </ModAnimation>
          </div>
        )
      } else {
        return null
      }
    }

    return (
      <div className="office">
        <ModBlockLead content={content.blockLead} />

        <div className="grid align-center">
          {/* <ModAnimation className="cell small-24 large-20 xlarge-18">
            <ModAnchorTag className="link link--internal" linkObject={content.lawyersLink}>
              <FormattedMessage id="office.all-lawyers" defaultMessage="All Lawyers" />{' '}
              {content.office._i18nCL.title && (
                <>
                  &nbsp;
                  {'in ' + content.office._i18nCL.title} <br />{' '}
                </>
              )}
            </ModAnchorTag>
          </ModAnimation> */}

          {content.office.googleUrl && (
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <br />
              <a className="link link--internal" href={content.office.googleUrl} target="_blank">
                <FormattedMessage id="office.directions" defaultMessage="Get Directions" />
              </a>
            </ModAnimation>
          )}
        </div>

        {getContactInfo()}
        {/* {getPractiseAreas()}
        {getSectors()} */}
      </div>
    )
  }
}
