import * as React from 'react'
import { BlockJobIFrame } from 'interfaces/Interfaces'
import { injectIntl } from 'react-intl'
import ModAnimation from 'components/common/ModAnimation'
import { NavigationNodeContext } from 'components/ModApp'
import { FormattedMessage } from 'react-intl'

export interface ModBlockJobIFrameProps {
  content: BlockJobIFrame
  baseSrc: string
  intl: any
}

function ModBlockJobIFrame(props: ModBlockJobIFrameProps) {
  const { content, baseSrc, intl } = props

  let iframeRef = React.createRef<HTMLIFrameElement>()
  const [iFrameHeight, setIFrameHeigt] = React.useState(400)
  const [iFrameSrc, setIFrameSrc] = React.useState(null)
  const [iFrameNavigationCount, setIFrameNavigationCount] = React.useState(0)
  const [recheckCookie, setRecheckCookie] = React.useState(false)

  if (typeof window !== 'undefined') {
    window.addEventListener('CookiebotOnLoad', function (e) {
      setRecheckCookie(true)
    })
  }

  var pageName = ''

  const { router } = React.useContext(NavigationNodeContext)

  const PORTAL_ID_KEY = 'jobportal_id'
  const JOB_ID_KEY = 'jobid'
  const PAGENAME_KEY = 'page'
  let pageLoaded = false

  React.useEffect(() => {
    window.addEventListener('message', changeURL)
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', changeURL)
      window.removeEventListener('message', onMessage)
    }
  }, [])

  React.useEffect(() => {
    // only load iframe if statistics cookies are accepted
    // @ts-ignore
    if (Cookiebot.consent.statistics) {
      changeIFrameSrc()
    } else {
      setIFrameSrc(null)
    }

    setIFrameNavigationCount(0)
  }, [intl, recheckCookie])

  React.useEffect(() => {
    if (iFrameNavigationCount > 1) {
      iframeRef.current.scrollIntoView()
    }
  }, [iFrameNavigationCount])

  // change i frame src according to url
  function changeIFrameSrc() {
    const iframeParamsMap = new Map()
    var localeString = window.location.pathname.split('/')[1]
    iframeParamsMap.set(PAGENAME_KEY, getQueryVariable(PAGENAME_KEY) || 'jobdetail')
    iframeParamsMap.set(PORTAL_ID_KEY, getQueryVariable(PORTAL_ID_KEY) || '1')
    iframeParamsMap.set(JOB_ID_KEY, getQueryVariable(JOB_ID_KEY))
    if (iframeParamsMap.get(PORTAL_ID_KEY) && iframeParamsMap.get(JOB_ID_KEY)) {
      // const baseUrl = baseSrc.replace('jobportal', paramsMap.get(PAGENAME_KEY))
      // const url = getUrlWithParams(baseUrl, paramsMap)
      const url = `${baseSrc}/${localeString}/job_${iframeParamsMap.get(PORTAL_ID_KEY)}_${iframeParamsMap.get(
        JOB_ID_KEY
      )}/${iframeParamsMap.get(PAGENAME_KEY)}`
      setIFrameSrc(url)
    } else {
      // update language
      setIFrameSrc(`${baseSrc}/${localeString}${content.iframeLink}`)
    }
    iframeParamsMap.clear()
    pageLoaded = true
  }

  // change url according to iframe src
  function changeURL(e) {
    if (e.data.event_id === 'job_navigation' && pageLoaded) {
      if (e.data.is_overview !== 1) {
        let queryParams = new URLSearchParams()
        queryParams.append(PORTAL_ID_KEY, e.data.jobportal_id)
        queryParams.append(JOB_ID_KEY, e.data.job_id)
        router.replaceSearchParams(queryParams)
      } else {
        let queryParams = new URLSearchParams()
        router.replaceSearchParams(queryParams)
      }
    }
  }

  function getUrlWithParams(baseUrl, paramsMap) {
    let url = baseUrl
    let first_iteration = true
    for (let [key, value] of paramsMap.entries()) {
      if (value) {
        if (first_iteration) {
          url = url.concat('?')
          first_iteration = false
        } else {
          url = url.concat('&')
        }
        url = url.concat(key, '=', value)
      }
    }
    return url
  }

  function getQueryVariable(variable) {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (pair[0] === variable) {
        return pair[1]
      }
    }
    return false
  }

  function onMessage(e) {
    if (e.data.event_id === 'send_height') {
      setIFrameHeigt(e.data.height)
    } else if (e.data.event_id === 'document_ready') {
      if (pageName !== e.data.page_name) {
        setIFrameNavigationCount((iFrameNavigationCount) => iFrameNavigationCount + 1)
      }
      pageName = e.data.page_name
    }
  }

  return (
    <div className="block-job-iframe grid align-center">
      <ModAnimation className="cell small-24 large-20 xlarge-18">
        <div className="block-job-iframe__modal__wrapper">
          <div className="cookieconsent-optout-statistics">
            <div className="cookieconsent-optout-statistics-custom">
              <p>
                <a
                  onClick={() => {
                    // @ts-ignore
                    window.Cookiebot.show()
                  }}
                >
                  <FormattedMessage
                    id="cookiePolicy.acceptStatistics"
                    defaultMessage="Please accept statistics cookies to see this content."
                  />
                </a>
              </p>
            </div>
          </div>

          <iframe
            key={iFrameSrc}
            data-cookieconsent="statistics"
            className="job-iframe"
            ref={iframeRef}
            id="myIframe"
            src={iFrameSrc}
            height={`${iFrameHeight}px`}
            sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-downloads allow-modals"
          ></iframe>
        </div>
      </ModAnimation>
    </div>
  )
}

export default injectIntl(ModBlockJobIFrame)
