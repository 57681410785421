import * as React from 'react'
import { BlockLead } from "../../interfaces/Interfaces";
import ModAnimation from "../common/ModAnimation";

export interface ModBlockLeadProps {
  content: BlockLead
}

export default class ModBlockLead extends React.Component<ModBlockLeadProps, {}> {

  render() {
    const { content } = this.props

    if (!(content._i18nCL && content._i18nCL.leadText)) {
      return null
    }
    const i18n = content._i18nCL

    return (
      <div className="block-lead grid align-center">
        <ModAnimation className="cell small-24 large-20 xlarge-18">
          <p className="block-lead__text">{i18n.leadText}</p>
        </ModAnimation>
      </div>

    )
  }
}