import { stripSlashes, addCurrentLangRecursive } from './Utils'
import { routes } from '../vo/Routes'
import {
  CloudinaryMedia,
  IPublicSettings,
  NavigationNode,
  Page,
  PageType,
  StaticPage,
  QueryParam,
  Link,
  BlockTitle,
  BlockNewsPublicationsGrid,
  BlockNewsPublicationsGridType,
  News,
  Publication,
  IPage,
} from '../interfaces/Interfaces'

export function isArticleActiveNow(page: Page, currentLanguage: string): boolean {
  if (!page) return false

  let i18nCL

  if (page.i18n && page.i18n.hasOwnProperty(currentLanguage)) {
    page.i18n.hasOwnProperty(currentLanguage)
    i18nCL = page.i18n[currentLanguage]
  } else if (!page.i18n) {
    i18nCL = page._i18nCL
  }

  if (!i18nCL) return false

  const now = new Date().getTime()
  if (page.expiryDate && now > new Date(page.expiryDate).getTime()) {
    return false
  }

  if (page.publishDate && now < new Date(page.publishDate).getTime()) {
    return false
  }

  return i18nCL.isLive
}

/**
 * searches an navigation node by his url and returns his id or null if not found
 * @param node
 * @param searchRelativeUrl
 * @param currentLanguage
 * @returns String|null
 */
export function searchNavigationNodeByUrl(
  node: NavigationNode,
  searchRelativeUrl: string,
  currentLanguage: string
): NavigationNode | null {
  if (!node) return null

  if (currentLanguage) {
    // find by provided language
    if (node._i18nCL) {
      if (stripSlashes(node._i18nCL.relativeUrl) === stripSlashes(searchRelativeUrl)) {
        return node
      }
    } else {
      const i18n = node.i18n.hasOwnProperty(currentLanguage) ? node.i18n[currentLanguage] : null
      if (i18n && stripSlashes(i18n.relativeUrl) === stripSlashes(searchRelativeUrl)) {
        return node
      }
    }
  } else {
    const found = Object.entries(node.i18n).find((item) => {
      const [langKey, i18n] = item
      return stripSlashes(i18n.relativeUrl) === langKey + '/' + stripSlashes(searchRelativeUrl)
    })
    if (found) {
      return node
    }
  }

  for (let childNode of Object.values(node.children)) {
    const foundNode = searchNavigationNodeByUrl(childNode, searchRelativeUrl, currentLanguage)
    if (foundNode) {
      return foundNode
    }
  }
}

export function getArticleByNodeId(pages: Page[], nodeId: string): Page | null {
  if (!pages) {
    return null
  }
  for (let page of Object.values(pages)) {
    if (page.navigationNode === nodeId) {
      return page
    }
  }
  return null
}

export function getPageIdByNodeId(pages: Page[], nodeId: string): string | null {
  if (!pages) {
    return null
  }
  for (let entry of Object.entries(pages)) {
    const [id, page] = entry
    if (page.navigationNode === nodeId) {
      return id
    }
  }
  return null
}

/**
 * replaces media domain in the url field for nested results
 * @param voConfig
 * @param result
 */
export function replaceMediaDomainRecursive(publicConfig: IPublicSettings, result: object): void {
  if (!(typeof result === 'object') || result == null) {
    return
  }
  for (let object of Object.entries(result)) {
    const [key, item] = object
    if (
      key === 'media' &&
      (item as CloudinaryMedia).backend &&
      (item as CloudinaryMedia).backend.cloudinary &&
      (item as CloudinaryMedia).extension
    ) {
      const media: CloudinaryMedia = item
      item.url = `/media/${publicConfig.cloudinaryCloudName}/image/upload/${media.backend.cloudinary.public_id}${media.extension}`
    }
    if (item instanceof Array) {
      replaceMediaDomainRecursive(publicConfig, item)
    }
    if (typeof item === 'object') {
      replaceMediaDomainRecursive(publicConfig, item)
    }
  }
}

export function createNodes(
  navigationNode: NavigationNode,
  pagePool?: Page[],
  parentNode?: NavigationNode,
  includePage = false
): void {
  Object.entries(navigationNode.i18n).forEach((item) => {
    const [langKey, i18n] = item
    if (parentNode && parentNode.i18n.hasOwnProperty(langKey)) {
      i18n.relativeUrl = parentNode.i18n[langKey].relativeUrl + '/' + i18n.slug
    } else {
      i18n.relativeUrl = '/' + i18n.slug
    }
  })
  if (pagePool) {
    let pageId = getPageIdByNodeId(pagePool, navigationNode._id)
    navigationNode.pageId = pageId
    if (includePage) {
      navigationNode.page = pageId != null ? pagePool[pageId] : null
    }
  }

  navigationNode.children.forEach((childNode) => {
    createNodes(childNode, pagePool, navigationNode, includePage)
  })
}

export function findNavigationNodeById(id: string, rootNavigationNode: NavigationNode): NavigationNode {
  if (rootNavigationNode._id === id) {
    return rootNavigationNode
  }
  for (let childNode of Object.values(rootNavigationNode.children)) {
    const result = findNavigationNodeById(id, childNode)
    if (result) {
      return result
    }
  }
  return null
}

export function createUrlLinkObject(url: string): Link {
  let urlLinkObject = {
    url: {
      _i18nCL: url,
    },
  }
  return urlLinkObject
}

export function createStaticPageLinkObject(page: StaticPage, type: PageType, lang: string = null): Link {
  let staticLinkObject = {
    staticPage: {},
  }
  staticLinkObject.staticPage[type] = page
  if (lang) staticLinkObject.staticPage['language'] = lang

  return staticLinkObject
}

export function createNodeLinkObject(node: NavigationNode, queryParams: QueryParam[] = null, lang: string = null): Link {
  let link = {
    internalLink: {
      node: node,
    },
  }

  if (queryParams) link.internalLink['queryParams'] = queryParams
  if (lang) link.internalLink['language'] = lang
  return link
}

export function createBlockTitleObject(title: string): BlockTitle {
  return {
    _i18nCL: {
      title: title,
    },
  }
}

export function createBlockNewsPublicationsGrid(
  type: BlockNewsPublicationsGridType,
  title: string,
  items: (News | Publication)[],
  link: Link,
  moreLinkText?: string
): BlockNewsPublicationsGrid {
  return {
    type: type,
    _i18nCL: {
      title: title,
    },
    items: items,
    moreLink: link,
    moreLinkText: moreLinkText,
  }
}
