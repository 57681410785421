import * as React from 'react'
import { injectIntl } from 'react-intl'
import { SearchResult } from 'interfaces/Interfaces'
import * as isoFetch from 'isomorphic-fetch'
import { searchQueryParams } from 'vo/SearchQueryParams'
import { NavigationNodeContext } from 'components/ModApp'
import { TweenMax } from 'gsap'
import { createUrlLinkObject } from 'common/CmsUtils'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModSvg from 'components/common/ModSvg'
import { FormattedMessage } from 'react-intl'

export interface ModBlockSearchProps {
  currentLanguage: string
  searchTerm: string
  searchType: string
  intl: any
}

function ModBlockSearch(props: ModBlockSearchProps) {
  const { location, router } = React.useContext(NavigationNodeContext)
  const { currentLanguage, searchTerm, searchType } = props

  const defaultResult = {
    total: 0,
    length: 0,
    offset: 0,
    pages: 0,
    entries: [],
  } as SearchResult

  const currentSearchReducer = (state, action) => {
    return {
      term: action.term || searchTerm,
      type: action.type || searchType,
      page: action.page || 1,
    }
  }

  const currentSearchInit = () => {
    let page = 1
    if (location.query) {
      page = parseInt(location.query.get(searchQueryParams.page) || '1')
    }
    return {
      term: searchTerm,
      type: searchType,
      page: page,
    }
  }

  const [currentSearch, setCurrentSearch] = React.useReducer(currentSearchReducer, null, currentSearchInit)
  const [result, setResult] = React.useState(defaultResult)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  React.useEffect(() => {
    setCurrentSearch({
      term: searchTerm,
      type: searchType,
    })
  }, [searchTerm, searchType])

  React.useEffect(() => {
    fetchResults()
  }, [currentSearch])

  const fetchResults = () => {
    if (!props.searchTerm || props.searchTerm == '') {
      setResult(defaultResult)
      router.replaceSearchParams(new URLSearchParams())
      return
    }

    let queryParams = new URLSearchParams()
    queryParams.append(searchQueryParams.term, props.searchTerm)
    if (currentSearch.page > 1) {
      queryParams.append(searchQueryParams.page, currentSearch.page.toString())
    }

    if (currentSearch.type != '') {
      queryParams.append(searchQueryParams.type, props.searchType)
    }
    router.replaceSearchParams(queryParams)

    queryParams.append(searchQueryParams.lang, currentLanguage)

    setIsError(false)
    setIsLoading(true)
    isoFetch('/api/search?' + queryParams.toString())
      .then(function (response) {
        return response.json()
      })
      .then((result: SearchResult) => {
        if (result.hasOwnProperty('error')) {
          throw new Error(result['error'])
        } else {
          setResult(result)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
        setIsError(true)
      })
  }

  function changePage(page) {
    scrollToStart()
    setCurrentSearch({
      page: page,
    })
  }

  const createPaginationElement = () => {
    const pageCount = result['pages'] || null
    const currentPage = currentSearch.page

    if (!pageCount || pageCount <= 1) return null
    const first = 1
    const last = pageCount
    let startEllipsis = false
    let endEllipsis = false
    let pages = []

    if (pageCount <= 5) {
      //ex: 1,2,3,4,5
      for (let i = 1; i <= pageCount; i++) {
        pages.push(i)
      }
    } else {
      if (currentPage <= 3) {
        //ex: 1,2,3...,10
        endEllipsis = true
        pages.push(first)
        if (currentPage - 1 > 0) pages.push(currentPage - 1)
        pages.push(currentPage)
        pages.push(currentPage + 1)
      } else if (currentPage >= pageCount - 2) {
        //ex: 1,...,8,9,10
        startEllipsis = true
        pages.push(currentPage - 1)
        pages.push(currentPage)
        if (currentPage + 1 <= last) pages.push(currentPage + 1)
        pages.push(last)
      } else {
        //ex: 1,...,5,6,7...10
        startEllipsis = true
        endEllipsis = true
        pages.push(currentPage - 1)
        pages.push(currentPage)
        pages.push(currentPage + 1)
      }
    }
    pages = Array.from(new Set(pages))

    let backButton =
      currentPage > first ? (
        <li
          key={0}
          onClick={() => {
            changePage(currentPage - 1)
          }}
        >
          <ModSvg icon={'arrow-left'} />
        </li>
      ) : null
    let forwardButton =
      currentPage < last ? (
        <li
          key={pages.length + 5}
          onClick={() => {
            changePage(currentPage + 1)
          }}
        >
          <ModSvg icon={'arrow-right'} />
        </li>
      ) : null
    let startElement = startEllipsis
      ? [
          <li
            key={1}
            onClick={() => {
              changePage(first)
            }}
          >
            {first}
          </li>,
          <li key={2}>...</li>,
        ]
      : null
    let endElement = endEllipsis
      ? [
          <li key={pages.length + 3}>...</li>,
          <li
            key={pages.length + 4}
            onClick={() => {
              changePage(last)
            }}
          >
            {last}
          </li>,
        ]
      : null

    let pageElements = pages.map((page, index) => {
      const active = page == currentPage
      return (
        <li
          className={'' + (active ? 'is-active' : '')}
          key={index + 3}
          onClick={() => {
            changePage(page)
          }}
        >
          {page}
        </li>
      )
    })

    return (
      <div className={'grid align-center'}>
        <div className="cell small-24 large-20 xlarge-18">
          <ul className={'pagination'}>
            {backButton}
            {startElement}
            {pageElements}
            {endElement}
            {forwardButton}
          </ul>
        </div>
      </div>
    )
  }

  const scrollToStart = () => {
    TweenMax.to(window, 1, { scrollTo: { y: '#search-result-top', offsetY: 100, autoKill: false } })
  }

  const createResultList = () => {
    if (searchTerm == '') {
      return null
    }

    if (result.entries.length == 0) {
      return (
        <h3 className={'block-search__result-title label'}>
          <FormattedMessage id="global.no-results" defaultMessage="No Results found" />.
        </h3>
      )
    }

    const entries = result.entries.map((entry, index) => {
      const url = new URL(entry.url)
      const label = entry.label !== '' ? <span className="label block-search__teaser__label">{entry.label}</span> : null
      return (
        <div className="block-search__teaser" key={index}>
          {label}
          <h3 className="block-search__teaser__title">{entry.title}</h3>
          <p className="block-search__teaser__text">{entry.description}</p>
          <ModAnchorTag className={'link--internal'} linkObject={createUrlLinkObject(url.pathname)}>
            <FormattedMessage id="global.read-more" defaultMessage="Read more" />
          </ModAnchorTag>
        </div>
      )
    })

    return (
      <div>
        <h3 className={'block-search__result-title label'}>
          <FormattedMessage id="global.results-for" defaultMessage="Results for" /> "{searchTerm}"
        </h3>
        {entries}
      </div>
    )
  }

  return (
    <div id="search-result-top" className="block-search">
      <div className={'grid align-center'}>
        <div className="cell small-24 large-20 xlarge-18">{createResultList()}</div>
      </div>
      {createPaginationElement()}
    </div>
  )
}

export default injectIntl(ModBlockSearch)
