import * as React from 'react'
import { BlockImageText } from '../../interfaces/Interfaces'
import ModRichText from '../common/ModRichText'
import ModImgTag from '../common/ModImgTag'
import ModAnimation from '../common/ModAnimation'
import ModAnchorTag from '../common/ModAnchorTag'
import ModBlockTitle from './ModBlockTitle'
import { createBlockTitleObject } from 'common/CmsUtils'

export interface ModBlockImageTextProps {
  content: BlockImageText
}

export default class ModBlockImageText extends React.Component<ModBlockImageTextProps, {}> {
  render() {
    const { content } = this.props

    if (!content._i18nCL) {
      return null
    }
    const i18n = content._i18nCL

    let link = null
    if (content.link) {
      link = (
        <ModAnchorTag linkObject={content.link} linkText={true} hasIcon={true}>
          {i18n.linkText}
        </ModAnchorTag>
      )
    }

    return (
      <React.Fragment>
        {content._i18nCL.mainTitle && <ModBlockTitle content={createBlockTitleObject(content._i18nCL.mainTitle)}></ModBlockTitle>}
        <div className="block-image-text grid align-middle">
          <ModAnimation className="cell small-24 medium-24 large-14">
            <ModImgTag imgObject={content.image} width={1280} height={720} transformation={'c_fit'} />
          </ModAnimation>
          <div className="cell small-24 medium-20 large-8 large-offset-1">
            <ModAnimation className="block-image-text__content">
              <h3>{i18n.title}</h3>
              <ModRichText richText={i18n.text} />
              {link}
            </ModAnimation>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
