import {
  LawyerPosition,
  Office,
  NewsCategory,
  PublicationCategory,
  IPage,
  ExpertiseCategory,
  Translations,
} from 'interfaces/Interfaces'

/**
 *
 * @param offices
 * @param position
 * @returns string
 */
export function getLayerPositionByOffice(offices: Office[], position: LawyerPosition) {
  let orginalTitle = position._i18nCL && position._i18nCL.title ? position._i18nCL.title : null

  if (offices.length === 1) {
    offices.map((item) => {
      const officeId = item._id ? item._id : item

      return position.items
        .filter((element) => {
          const officeIdPosition = element.office._id ? element.office._id : element.office

          if (officeIdPosition !== officeId) {
            return false
          }
          return true
        })
        .map((item) => {
          orginalTitle = item._i18nCL.title
          return
        })
    })
  }

  return orginalTitle
}

export function getNewsCategoryTranslationKey(category: NewsCategory) {
  switch (category) {
    case NewsCategory.corporateNews:
      return 'newsCategory.corporateNews'
    case NewsCategory.dealsAndCases:
      return 'newsCategory.dealsAndCases'
    case NewsCategory.event:
      return 'newsCategory.event'
  }
}

export function getPublicationCategoryTranslationKey(category: PublicationCategory) {
  switch (category) {
    case PublicationCategory.briefing:
      return 'publicationCategory.briefing'
    case PublicationCategory.publication:
      return 'publicationCategory.publication'
    case PublicationCategory.speakingEngagement:
      return 'publicationCategory.speakingEngagement'
    case PublicationCategory.guide:
      return 'publicationCategory.guide'
  }
}

export function getLawyerTranslationKey() {
  return 'lawyers'
}

export function getExpertiseTranslationKey(category: ExpertiseCategory) {
  switch (category) {
    case ExpertiseCategory.practice:
      return 'expertiseCategory.practiceArea'
    case ExpertiseCategory.sector:
      return 'expertiseCategory.sector'
  }
}

export function geti18nClIfMissing(item) {
  if (!item._i18nCL) {
    if (item.i18n.de) {
      item._i18nCL = item.i18n.de
    }

    if (item.i18n.fr) {
      item._i18nCL = item.i18n.fr
    }

    if (item.i18n.en) {
      item._i18nCL = item.i18n.en
    }
  }

  return item
}
