import * as React from 'react'
import {
  News,
  Publication,
  BlockNewsPublicationsGridType,
  NewsPublication,
  NewsCategory,
  PublicationCategory,
  Lawyer,
  PageType,
} from 'interfaces/Interfaces'
import ModAnchorTag from './ModAnchorTag'
import ModImgTag from './ModImgTag'
import ModAnimation from './ModAnimation'
import { createStaticPageLinkObject } from 'common/CmsUtils'
import { getLayerPositionByOffice } from './Utils'

export interface ModLawyersTeaserProps {
  content: Lawyer
}

export default class ModLawyersTeaser extends React.Component<ModLawyersTeaserProps, {}> {
  render() {
    const { content } = this.props

    const positionTitle = getLayerPositionByOffice(content.offices, content.position)

    let getImage = null
    if (content.teaserImage) {
      getImage = <ModImgTag className="lawyer-teaser__image" imgObject={content.teaserImage} height={960} width={960} />
    } else {
      getImage = (
        <figure className="lawyer-teaser__image">
          <img src={require('static/img/_blank_square.gif')} alt="default" />
        </figure>
      )
    }

    let reactContent = (
      <ModAnchorTag linkObject={createStaticPageLinkObject(content, PageType.lawyer)}>
        <ModAnimation tag="article" className="lawyer-teaser">
          {getImage}

          <div className="lawyer-teaser__content">
            {positionTitle && (
              <>
                <span className="label lawyer-teaser__content__label">{positionTitle}</span>
              </>
            )}
            <h2 className="lawyer-teaser__content__title">
              {content.contact.academicTitle} {content.contact.firstName}{' '}
              <span className="lawyer-teaser__content__title-br">
                <br />
              </span>{' '}
              {content.contact.lastName}
            </h2>
          </div>
        </ModAnimation>
      </ModAnchorTag>
    )

    if (content.hideLawyer) {
      reactContent = (
        <ModAnimation tag="article" className="lawyer-teaser">
          {getImage}

          <div className="lawyer-teaser__content">
            {positionTitle && (
              <>
                <span className="label lawyer-teaser__content__label">{positionTitle}</span>
              </>
            )}
            <h2 className="lawyer-teaser__content__title">
              {content.contact.academicTitle} {content.contact.firstName} {content.contact.lastName}
            </h2>
          </div>
        </ModAnimation>
      )
    }

    return reactContent
  }
}
