import * as React from 'react'
import { BlockNewsPublicationsGrid, BlockNewsPublicationsGridType } from '../../interfaces/Interfaces'
import ModNewsPublicationsTeaser from '../common/ModNewsPublicationsTeaser'
import ModAnchorTag from 'components/common/ModAnchorTag'
import ModAnimation from 'components/common/ModAnimation'
import ModBlockTitle from './ModBlockTitle'
import { createBlockTitleObject } from 'common/CmsUtils'
import { FormattedMessage } from 'react-intl'
import { geti18nClIfMissing } from 'components/common/Utils'

export interface ModBlockNewsPublicationsGridProps {
  content: BlockNewsPublicationsGrid
  id?: string
  currentLanguage: string
}

export default class ModBlockNewsPublicationsGrid extends React.Component<ModBlockNewsPublicationsGridProps, {}> {
  render() {
    let { content, id, currentLanguage } = this.props
    let category = null

    if (content.items.length == 0) {
      return null
    }

    if (!id) {
      id = null
    }

    switch (content.type) {
      case 'publications': {
        category = <FormattedMessage id="lawyer.publications" defaultMessage="Publications" />
        break
      }
      case 'deals & cases': {
        category = <FormattedMessage id="lawyer.deals" defaultMessage="Deals, Cases & News" />
        break
      }
      default:
        break
    }

    const getBlockTitle = () => {
      if (content._i18nCL && content._i18nCL.title) {
        return <ModBlockTitle content={createBlockTitleObject(content._i18nCL.title)}></ModBlockTitle>
      } else {
        return null
      }
    }

    const newsPublications = content.items.map((item, index) => {
      item = geti18nClIfMissing(item)

      return (
        <div className="cell small-24 medium-12 large-12 xlarge-8" key={index}>
          <ModNewsPublicationsTeaser currentLanguage={currentLanguage} content={item} type={content.type} />
        </div>
      )
    })

    return (
      <div className="block-news-publications-grid" id={id}>
        {getBlockTitle()}
        <div className="grid align-center">
          <div className="cell small-24">
            <div className="grid news-publications">{newsPublications}</div>
            <div className="grid">
              <ModAnimation className="cell small-24 text-center">
                <ModAnchorTag linkObject={content.moreLink} className={'button button--hollow'}>
                  <FormattedMessage id="filter.all" defaultMessage="All" />{' '}
                  {content.moreLinkText ? content.moreLinkText : category}
                </ModAnchorTag>
              </ModAnimation>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
