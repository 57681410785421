import * as React from 'react'
import { blockTeam, Lawyer } from '../../interfaces/Interfaces'
import ModLawyersTeaser from '../common/ModLawyersTeaser'
import ModAnimation from '../common/ModAnimation'
import { FormattedMessage, injectIntl } from 'react-intl'

export interface ModBlockTeamProps {
  content: blockTeam
}

export default class ModBlockTeam extends React.Component<ModBlockTeamProps, {}> {
  render() {
    const { content } = this.props

    const getKeyContacts = () => {
      if (content.team.length > 0) {
        return <div className="grid">{keyContacts}</div>
      } else {
        return null
      }
    }

    const keyContacts = content.team.map((item, index) => {
      function contentForItem() {
        return (
          <ModAnimation>
            <span className="label">
              {item.title ? item.title : <FormattedMessage id="expertise.key-contact" defaultMessage="Key Contact" />}
            </span>
            <ModLawyersTeaser content={item.lawyer} />
          </ModAnimation>
        )
      }

      return (
        <div
          className={
            'cell small-24 medium-12 large-8 xlarge-6 ' +
            (index % 3 === 0 && index % 6 !== 0 ? 'xlarge-offset-5' : '') +
            (index % 6 === 0 ? 'xlarge-offset-3' : '')
          }
          key={index}
        >
          {contentForItem()}
        </div>
      )
    })

    return (
      <div className="block-team grid align-center">
        <div className="cell small-24 large-20 xlarge-18">{getKeyContacts()}</div>
      </div>
    )
  }
}
