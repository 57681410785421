import { hasContent } from './Utils'
import RootState from '../vo/RootState'
import { convertFromRaw } from 'draft-js'
import { PageType, Lawyer, Page, Expertise, Publication, News } from 'interfaces/Interfaces'
import {
  getPublicationCategoryTranslationKey,
  getNewsCategoryTranslationKey,
  getLawyerTranslationKey,
  getExpertiseTranslationKey,
} from 'components/common/Utils'

export function createMetaFields(rootState: RootState, currentLanguage: string) {
  const fields = {
    siteName: '',
    title: '',
    description: '',
    label: '',
    filter_type: '',
    keywords: '',
    shareImage_url: '',
    shareImage_secureUrl: '',
    shareImage_format: '',
    shareImage_width: 0,
    shareImage_height: 0,
  }

  // fallback
  const { websiteSettings } = rootState
  let translations
  if (websiteSettings) {
    try {
      translations = websiteSettings.translations.translations[currentLanguage]
    } catch (Exception) {
      translations = null
    }

    fields.siteName = websiteSettings._i18nCL.siteName
    fields.title = websiteSettings._i18nCL.title
    fields.description = websiteSettings._i18nCL.description

    if (websiteSettings.shareImage) {
      fields.shareImage_url = websiteSettings.shareImage.media.backend.cloudinary.url
      fields.shareImage_secureUrl = websiteSettings.shareImage.media.backend.cloudinary.secure_url
      fields.shareImage_format = 'image/' + websiteSettings.shareImage.media.backend.cloudinary.format
      fields.shareImage_width = websiteSettings.shareImage.media.backend.cloudinary.width
      fields.shareImage_height = websiteSettings.shareImage.media.backend.cloudinary.height
    }
  }

  if (
    rootState.pages &&
    rootState.currentPage &&
    rootState.pages.hasOwnProperty(rootState.currentPage.type) &&
    rootState.pages[rootState.currentPage.type].hasOwnProperty(rootState.currentPage.id)
  ) {
    let currentPage = rootState.pages[rootState.currentPage.type][rootState.currentPage.id]

    switch (currentPage._pageType) {
      case PageType.page:
        const page = currentPage as Page
        fields.title = page._i18nCL.title
        fields.description = page._i18nCL.description
        fields.filter_type = 'page'

        if (page.shareImage) {
          try {
            fields.shareImage_url = page.shareImage.media.backend.cloudinary.url
            fields.shareImage_secureUrl = page.shareImage.media.backend.cloudinary.secure_url
            fields.shareImage_format = 'image/' + page.shareImage.media.backend.cloudinary.format
            fields.shareImage_width = page.shareImage.media.backend.cloudinary.width
            fields.shareImage_height = page.shareImage.media.backend.cloudinary.height
          } catch (e) {
            console.log(e)
          }
        }

        if (page.content.templateDataProtection) {
          fields.label = 'Data Protection'
          fields.filter_type = 'dataProtection'
        }

        break
      case PageType.publication:
        const publication = currentPage as Publication
        if (publication._i18nCL) {
          fields.title = publication._i18nCL.title
          fields.description =
            publication._i18nCL.seoDescription !== '' ? publication._i18nCL.seoDescription : publication._i18nCL.teaserText
          fields.filter_type = publication.category

          if (translations) {
            const categoryLabel = translations[getPublicationCategoryTranslationKey(publication.category)] || ''
            fields.label = categoryLabel
            let category = [categoryLabel]
            let keywords = publication.expertise.map((item) => item._i18nCL.title)
            fields.keywords = category.concat(keywords).join(', ')
          }

          if (publication.image) {
            try {
              fields.shareImage_url = publication.image.media.backend.cloudinary.url
              fields.shareImage_secureUrl = publication.image.media.backend.cloudinary.secure_url
              fields.shareImage_format = 'image/' + publication.image.media.backend.cloudinary.format
              fields.shareImage_width = publication.image.media.backend.cloudinary.width
              fields.shareImage_height = publication.image.media.backend.cloudinary.height
            } catch (e) {
              console.log(e)
            }
          }
        }
        break
      case PageType.news:
        const news = currentPage as News
        if (news._i18nCL) {
          fields.title = news._i18nCL.title
          fields.description = news._i18nCL.seoDescription !== '' ? news._i18nCL.seoDescription : news._i18nCL.teaserText
          fields.filter_type = news.category

          if (translations) {
            const categoryLabel = translations[getNewsCategoryTranslationKey(news.category)] || ''

            fields.label = categoryLabel
            let category = [categoryLabel]
            let keywords = news.expertise.map((item) => item._i18nCL.title)
            fields.keywords = category.concat(keywords).join(', ')
          }

          if (news.image) {
            try {
              fields.shareImage_url = news.image.media.backend.cloudinary.url
              fields.shareImage_secureUrl = news.image.media.backend.cloudinary.secure_url
              fields.shareImage_format = 'image/' + news.image.media.backend.cloudinary.format
              fields.shareImage_width = news.image.media.backend.cloudinary.width
              fields.shareImage_height = news.image.media.backend.cloudinary.height
            } catch (e) {
              console.log(e)
            }
          }
        }
        break
      case PageType.lawyer:
        const laywer = currentPage as Lawyer
        if (laywer._i18nCL) {
          fields.title = `${laywer.contact.firstName} ${laywer.contact.lastName}`
          fields.description = convertFromRaw(laywer._i18nCL.description).getPlainText()
          fields.filter_type = 'lawyer'

          if (translations) {
            const label = translations[getLawyerTranslationKey()] || ''
            fields.keywords = label
            fields.label = label
          }

          if (laywer.headerImage) {
            try {
              fields.shareImage_url = laywer.headerImage.media.backend.cloudinary.url
              fields.shareImage_secureUrl = laywer.headerImage.media.backend.cloudinary.secure_url
              fields.shareImage_format = 'image/' + laywer.headerImage.media.backend.cloudinary.format
              fields.shareImage_width = laywer.headerImage.media.backend.cloudinary.width
              fields.shareImage_height = laywer.headerImage.media.backend.cloudinary.height
            } catch (e) {
              console.log(e)
            }
          } else if (laywer.teaserImage) {
            try {
              fields.shareImage_url = laywer.teaserImage.media.backend.cloudinary.url
              fields.shareImage_secureUrl = laywer.teaserImage.media.backend.cloudinary.secure_url
              fields.shareImage_format = 'image/' + laywer.teaserImage.media.backend.cloudinary.format
              fields.shareImage_width = laywer.teaserImage.media.backend.cloudinary.width
              fields.shareImage_height = laywer.teaserImage.media.backend.cloudinary.height
            } catch (e) {
              console.log(e)
            }
          }
        }
        break
      case PageType.expertise:
        const expertise = currentPage as Expertise
        if (expertise._i18nCL) {
          fields.title = expertise._i18nCL.title
          fields.description =
            expertise._i18nCL.seoDescription !== '' ? expertise._i18nCL.seoDescription : expertise._i18nCL.leadText
          fields.keywords = expertise._i18nCL.title
          fields.filter_type = 'expertise'

          if (translations) {
            const categoryLabel = translations[getExpertiseTranslationKey(expertise.category)] || ''
            fields.label = categoryLabel
          }
        }
        break
    }
  }
  return fields
}
